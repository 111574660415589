import React from 'react'
import { Link } from 'react-router-dom'

import SubNavBar from "../components/SubNavBar"
import SimpleFooter from '../components/SimpleFooter'
import Contact2 from '../components/Contact2'


const schedulingService =
<>
    <Link
        to="/scheduling"
        className="has-text-weight-bold add-margin-top-right button is-link shadow-lift"
    >
        Schedule Consultation
    </Link>
</>

const paymentInfo =
<>
    <Link
        to="/billing"
        className="add-margin-top-right has-text-weight-bold button is-link shadow-lift"
    >
        Payment Info
    </Link>
</>

const formInfo =
<>
    <Link
        to="/forms"
        className="add-margin-top-right has-text-weight-bold button is-link shadow-lift"
    >
        Forms
    </Link>
</>


class ResearchView extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    componentWillUnmount() {
        window.scrollTo(0, 0)
    }

    render () {
        return (

            <>
                <SubNavBar />
                <section className="hero is-medium is-primary is-bold">
                    <div className="hero-body service-page-bg-img"></div>
                </section>
                <section className="section">
                    <div className="container">
                        <h1 className="title">Research Collaborations</h1>
                        <div className="columns">
                            <div className="column content is-7">
                                <p className="is-size-5 is-size-6-mobile">
                                    Praxis Genomics is proud to be at the forefront of research and development related to genomic data acquisition and analysis. Although we are a CLIA and CAP compliant laboratory, approved to analyze medical patient samples and provide clinical grade reporting, we are also interested in collaborating with clinical or academic research groups on research projects that fall within our area of expertise.
                                    <br></br><br></br>Our CMO, Dr. Peter Nagy MD PhD, has a long history of such collaborative research efforts that have resulted in many <a href="https://scholar.google.com/citations?user=td0WLFkAAAAJ&hl=en&oi=ao">publications</a> published in prominent scientific journals.  Dr. Nagy has a research background and has been an NIH funded investigator at Columbia University with special interest in transcriptional regulation both through transcription factors as well as chromatin modifications and DNA methylation.  This research background in genomics, epigenomics and transcriptomics is what allowed him to develop and introduce into clinical use the combined testing approaches including Bionano optical mapping, whole genome sequencing and transcriptome analysis and single molecule sequencing approaches pioneered by Pacific Biosystems and Oxford Nanopore Technologies.
                                    <br></br><br></br>Our scientific advisory board has been a big part of these efforts and Dr. Nagy has published several articles together with these world renown scientist colleagues over the last two decades.
                                    <br></br><br></br>If you are a clinician and need help with design and performance of a research project, or a scientist who is looking for a dedicated comprehensive sequencing laboratory to generate data for grants or original publications, please contact Dr. Nagy directly at 914-343-8999 or via email at <a href="mailto:plnagy@praxisgenomics.com"> plnagy@praxisgenomics.com</a>.
                                </p>
                                <div className="padding-top">
                                    {schedulingService}
                                    {paymentInfo}
                                    {formInfo}
                                </div>
                            </div>
                            <div className="column content is-5">
                                <h3>Contact Form</h3>
                                <Contact2 id="Research" />
                            </div>
                        </div>
                    </div>
                </section>
                <SimpleFooter />
            </>
        );
    }
};

export default ResearchView;
