import React, { Component } from 'react'
import {ShippingInformation} from './praxisData.js'
import SubNavBar from "../components/SubNavBar"
import Simplefooter from "../components/SimpleFooter"
import "./Shippingpage.css"


export class ShippingPage extends Component {
    render() {
        return (
            <div>
                <SubNavBar/>
                <div className="container" >
                    <div style={{ margin:"10px", marginTop: "30px"}}>
                        {ShippingInformation}
                    </div>
                </div>
                <Simplefooter/>
            </div>
        )
    }
}

export default ShippingPage

