import React, { useState } from "react";
import emailValidator from 'email-validator';

const DownloadCard = ({ pdfUrl1, pdfUrl2, pdfUrl3 }) => {

	const [formState, setFormState] = useState({ firstname: "", lastname: "", email: "", message: "Downloaded brochures!"});
	
	const [fadeClass, setFadeClass] = useState('');
	const [boxClass, setBoxClass] = useState('');
	
	const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

	const { firstname, lastname, email } = formState;

	const isValidEmail = emailValidator.validate(email);
	const areFieldsFilled = Boolean(email && firstname && lastname);

	const encode = (data) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (areFieldsFilled && isValidEmail) {
			fetch("/", {
				method: "POST",
				headers: { "Content-Type": "application/x-www-form-urlencoded" },
				body: encode({ "form-name": "contact", ...formState })
			})
			.then(() => {
				alert("Success!")
				setError(null);
				setFadeClass('fade-out');
				setTimeout(() => {
					setSuccess(true);
					setBoxClass('transition-height');
				}, 1000);
			})
			.catch(error => {
				alert(error)
				setSuccess(false);
			});
		} else {
			setError('Please enter all fields with a valid email');
			setSuccess(false);
		}
	};

	const handleChange = (e) => {
		setFormState({...formState, [e.target.name]: e.target.value});
	};

	return (
		<div className={`is-shady card ${boxClass}`} id="brochures" >
			<header className="card-header">
				<h1 className="card-header-title"> Download our Brochures! </h1>
			</header>
			<div className="content">
				<div className="card-content">
					<form onSubmit={handleSubmit}>
						{!success && (
							<>
								<div className={`field is-horizontal ${fadeClass}`}>
									<div className="field-body">
										<div className="field">
											<input 
												className="input" 
												type="text"
												name="firstname"
												id="firstname"
												placeholder="First Name" 
												value={firstname} 
												onChange={handleChange} 
											/>
										</div>
										<div className="field">
											<input 
												className="input" 
												type="text"
												name="lastname"
												id="lastname"
												placeholder="Last Name" 
												value={lastname} 
												onChange={handleChange} 
											/>
										</div>
									</div>
								</div>
								<div className={`field is-horizontal ${fadeClass}`}>
									<div className="field-body">
										<div className="field">
											<input 
												className="input" 
												type="email" 
												name="email"
												id="email"
												placeholder="Enter your email" 
												value={email} 
												onChange={handleChange} 
											/>
										</div>
									</div>
								</div>
								<p>
									<button className="button is-primary" type="submit">Send</button>
								</p>
							</>
						)}
						{error && <p className={`help is-danger ${fadeClass}`}>{error}</p>}
						{success && (
							<div className="fade-in">
								<p className="help is-success">Email Accepted!</p>
								<div className="field is-grouped">
									<p className="control">
										<a className="button is-link" href={pdfUrl3} download>Test List</a>
									</p>
									<p className="control">
										<a className="button is-link" href={pdfUrl1} download>Bi-Fold</a>
									</p>
									<p className="control">
										<a className="button is-link" href={pdfUrl2} download>Tri-Fold</a>
									</p>
								</div>
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	);
}

export default DownloadCard;
