import React from 'react'
import "../CSS/Contact.css"
import phoneIcon from "../../assets/icons/phone-alt-solid.svg"
import mapIcon from "../../assets/icons/map-marked-solid.svg"
import clockIcon from "../../assets/icons/clock-regular.svg"

function Contact() {
    return (
        <div className="section " id="section4">
            <h2 className="title  is-upper-alpha is-size-3-mobile is-size-1 is-size-1-tablet is-size-1-desktop has-text-centered">
                Contact
            </h2>
            <div className="divider black"></div>
            {/* <div className="columns is-vcentered"> */}
            <div className="columns">
                <div className="column is-full-mobile is-4 is-offset-1">
                    <div className="columns">
                        <div className="column has-text-left table-row">
                            <div className="table-cell table-cell-icon">
                                <img src={mapIcon} className="social-img" alt="Icon for Google"></img>
                            </div>
                            <div className="table-cell">
                                <p>
                                    6115 Peachtree Dunwoody Road, Suite 220
                                </p>
                                <p>
                                    Atlanta, GA 
                                </p>
                                <p>
                                    30328
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column has-text-left table-row">
                            <div className="table-cell table-cell-icon">
                                <img src={phoneIcon} className="social-img-phone" alt="Icon for Google"></img>
                            </div>
                            <div className="table-cell">
                                <p>
                                    (678) 837-4022
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column has-text-left table-row">
                            <div className="table-cell table-cell-icon">
                                <img src={clockIcon} className="social-img-phone" alt="Icon for Google"></img>
                            </div>
                            <div className="table-cell">
                                Hours of Operation
                                <br></br>
                                Monday - Friday
                                <br></br>
                                8AM - 6PM
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-7 ">
                    <form name="contact" className="netlify-contact-form" method="post" data-netlify="true">
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">From </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <input className="input " type="text" name="firstname" id="firstname" placeholder="First Name"></input>
                                </div>
                                <div className="field">
                                    <input className="input " type="text" name="lastname" id="lastname" placeholder="Last Name"></input>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Email </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <input className="input " type="email" name="email" id="email" placeholder="Email"></input>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Phone Number </label>
                            </div>
                            <div className="field-body">
                                <input className="input " type="text" name="phonenumber" id="phonenumber" placeholder="Phone Number"></input>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Question </label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <textarea className="textarea "
                                                  placeholder="Explain how we can help you"
                                                  name="message"
                                                  id="message"
                                                  rows="7"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="field">
                                    <div data-netlify-recaptcha="true">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label">
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input className="button is-primary" type="submit" value="Send Message"></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact
