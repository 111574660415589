import React from 'react';

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import Home from './app/views/Home.js';
import PaymentInfoPage from './app/views/PaymentInfoPage.js';
import SchedulingPage from './app/views/SchedulingPage.js';
import FormsPage from './app/views/FormsPage';
import ResearchView from './app/views/ResearchView';
import TestList from './app/views/TestList'
import ShippingPage from './app/views/ShippingPage.js';
import ScrollButton from './app/components/ScrollButton.js';
import NotFoundPage from './app/components/NotFoundPage.js';

class App extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/billing" component={PaymentInfoPage}/>
                    <Route exact path="/scheduling" component={SchedulingPage}/>
                    <Route exact path="/forms" component={FormsPage}/>
                    <Route exact path="/research" component={ResearchView}/>
                    <Route exact path="/test-list" component={TestList}/>
                    <Route exact path="/shipping" component={ShippingPage}/>
                    <Route path="/*" component={NotFoundPage}/>
                </Switch>
                <ScrollButton />
            </Router>
        )
    }
}


export default App;
