import React from "react"
import '../CSS/SimpleFooter.css'

function SimpleFooter(props) {

    return (
        <div className="hero-head padding-spacer border-top is-medium is-bold is-above-all">

            <div className="flexbox">
                <p>&copy; Praxis Genomics, LLC 2020</p>
            </div>
        </div>
    );
}

export default SimpleFooter