import React from 'react'
import '../CSS/IntroSection.css'
import Maps from '../components/Maps'
        
function IntroSection(props) {
    return (
        <>
        <Maps
        id="maps"
        />
        <div className="section is-relative upper-layer">
            <div className="container">
                <div className="column justify-text">
                    <h3 className="is-size-5-mobile is-size-4-tablet is-size-3-desktop">{props.subtitle}</h3>
                </div>
            </div>
        </div>
        </>
    )
}

export default IntroSection
