import React from 'react'

import SimpleFooter from '../components/SimpleFooter'
import './ServicesPage.css'

import dc from "../../assets/forms/diagnosticconsent.pdf"
import phi from "../../assets/forms/phi.pdf"
import nys from "../../assets/forms/nys.pdf"
import drcf from "../../assets/forms/drcf.pdf"

import ngs from "../../assets/forms/NGS.pdf"
import shipDec from "../../assets/code-info/ShippersDeclaration.pdf"
import SubNavBar from "../components/SubNavBar"

const titles =  [
                  {link:dc, name:'Diagnostic Consent Form'},
                  {link:phi, name:'Authorized Protected Health Information Release Form'},
                  {link:nys, name:'New York State Non-Permitted Laboratory Test Request Approval Form'},
                  {link:drcf, name:'Data Release Consent Form'},
                  {link:ngs, name:'NGS Data Release Request Form'},
                  {link:shipDec, name:"Shipper's Declaration"}
                ]

class FormsPage extends React.Component {
    componentDidMount() {
      window.scrollTo(0, 0)
    }
    render () {
        return (
          <>
          <SubNavBar />
           <div className="section container">
            <div className="columns">
                <div className="column tab is-size-5">
                    <div className="content">
                        <h3 className="">Additional Forms</h3> 
                        <h5 className="">Instructions for TRF completion, Shipping and Billing</h5> 
                        <p className=""> <strong> The Patient Information</strong> section must be filled out completely and include the patient's full name, date of birth, sex, specimen collection date, shipping condition and specimen type. Clinician use patient identification numbers such as MRNs may also be included, as well as ICD-10 codes. Any clinical information pertaining to the patient may be attached to the TRF if believed it would aid in a diagnosis.</p> 
                        <p className=""><strong>The Submitting Provider Information</strong> section is required and must have both a NPI number (or international equivalent) and signature for the physician. The phone number and email fields here may be used to contact the provider for testing updates, order clarifications and informational discrepancies. Like final reports, Emails are the preferred correspondence methods. All information included in this section will be stored for future use to associate patients and cases with institutions. The institution/physician may also denote additional emails they wish to include for final report delivery. The facility/physician must provide the name, contact information and NPI number as well for any secondary providers. </p> 
                        <p className="">Billing is handled through an invoice system, and an invoice must be generated for your specimens before being shipped to Praxis Genomics. Include the unique Invoice ID generated for you in the <strong>Submitting Provider Information</strong> section of the Test Request Form. Samples received with incorrect or missing invoice information to match with payment will be delayed or possibly rejected depending on collection date.</p>
                        <p>Contact us at 678-837-4022 or <a href="mailto:accounts@praxisgenomics.com"> accounts@praxisgenomics.com</a> to make arrangements for testing payment before specimen shipment.</p>
                    </div>
                    {titles.map(title => 
                        <div className="padding-link ">
                            <a className="has-text-link is-size-5" href={title.link} target="_blank" rel="noopener noreferrer">{title.name}</a>
                        </div>
                    )}
                </div>
            </div>
          </div>
          <SimpleFooter />
          </>
        )
    }
}

export default FormsPage
