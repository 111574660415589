import React from "react";
import "../CSS/Contact.css";

function Contact2(props) {

    return (
        <form name="contact" className="netlify-contact-form" method="post" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">From </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <input className="input" type="text" name="firstname" id="firstname" placeholder="First Name"></input>
                    </div>
                    <div className="field">
                        <input className="input" type="text" name="lastname" id="lastname" placeholder="Last Name"></input>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Email </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <input className="input" type="email" name="email" id="email" placeholder="Email"></input>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Phone Number </label>
                </div>
                <div className="field-body">
                    <input className="input" type="text" name="phonenumber" id="phonenumber" placeholder="Phone Number"></input>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">Question </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <textarea className="textarea"
                                    placeholder="Explain how we can help you"
                                    name="message"
                                    id="message"
                                    rows="7"
                            ></textarea>
                        </div>
                    </div>
                    <div className="field">
                        <div data-netlify-recaptcha="true">
                        </div>
                    </div>
                </div>
            </div>

            <div className="field is-horizontal">
                <div className="field-label">
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <input className="button is-primary" type="submit" value="Send Message"></input>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Contact2;