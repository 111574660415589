import React, { Component } from 'react'
import {genericSampleRequirements, services} from './praxisData.js'

import Icon from '@mdi/react'

import { mdiListBoxOutline } from '@mdi/js';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import consttrf from "../../assets/forms/trf/TRF Constitutional Version 9.pdf"
import somtrf from "../../assets/forms/trf/TRF Somatic Version 3.pdf"
import hertrf from "../../assets/forms/trf/TRF Heredity Version 3.pdf"
import carrtrf from "../../assets/forms/trf/TRF Carrier Version 3.pdf"

import icc from "../../assets/forms/CarrierIndividualConsent.pdf"
import ccc from "../../assets/forms/CarrierCoupleConsent.pdf"
import dc from "../../assets/forms/diagnosticconsent.pdf"
import hrConsentf from "../../assets/forms/HRConsentForm.pdf"
import specChart from "../../assets/images/samplespec.png"

export class TestBox extends Component {

    state = {
        expanded: false,
        expandButton: "Description "
    }

    expand = () => {
        this.setState(prevState => ({
            expanded: !this.state.expanded,
            expandButton: (this.state.expandButton === "Description " ? "Show Less " : "Description ")
        }))
    }

    fillDetails = (props) => {

        var chosen_tests;
        var supplementaryInfo = ""
        if (this.props.chosen_test.split(' ').length > 1) {
            chosen_tests = this.props.chosen_test.split(' ')[1].toLowerCase() + "Tests"
        } else {
            chosen_tests = this.props.chosen_test.split(' ')[0].toLowerCase() + "Tests"
        }

        if (props.title === "Combination Testing" || props.title === "External Whole Genome Data Analysis") {
            supplementaryInfo =
            <>
                <br></br>
                <p className={"has-text-weight-bold"}>Please Note: </p>
                <div className="tab">
                    {genericSampleRequirements}
                    <br></br>
                </div>
            </>
        } else {
            supplementaryInfo =
            <>
                <br></br>
                <p className={"has-text-weight-bold"}>Please Note: </p>
                <div className="tab">
                    {genericSampleRequirements}
                    <br></br>
                </div>
            </>
        }
        return services.map(test => (test.title === props.title && test.testCat.includes(this.props.chosen_test.split(" ")[0]) && test.type === this.props.chosen_type) ?
        <>
            {/* This is the actual TestBox */}
            <div id={test.id} className={"bumper-top TestBox small-section card " + ((this.props.chosen_test === "") ? "is-hidden" : "fade-in")}>
                <h1 className={"has-text-weight-bold"} style={{fontSize: "35px"}}>{test.title}</h1>
                {test.pla &&
                    <h1 className={"has-text-weight-semibold"} style={{fontSize: "15px"}}>PLA Code: {test.pla}</h1>                
                }
                <br></br>
                <div style={{fontSize: "20px"}}>
                    {(() => {
                        if (this.props.chosen_test.includes("Carrier")) {
                            if (this.props.chosen_test.includes("Individual")) {
                                return (
                                    test.individualIntro
                                )
                            } else if (this.props.chosen_test.includes("Couple")) {
                                return (
                                    test.coupleIntro                                
                                )
                            } 
                        } else {
                            return (
                                test.intro
                            )
                        }
                    })()}
                    {/* OG LINE */}
                    {/* {test.intro} */}
                </div>
                <hr style={{backgroundColor: 'black'}}></hr>
                <b style={{fontSize:'20px'}}>Available Tests:</b><br></br>
                <div className="text-column" >
                {
                Object.keys(test[chosen_tests]).map(function(key){
                    if (test[chosen_tests][key][1]) {
                        return (
                        <>
                            <p>
                                <b>
                                    <span style={{fontSize:'18px', borderStyle:'1px solid'}}>{key}</span>
                                </b>
                                <i>
                                    <span style={{paddingRight: "10px", fontSize: '15px'}}>{" - " + test[chosen_tests][key][0]}</span>
                                    {/* <span style={{paddingRight: "10px", fontSize: '15px'}}>{" - " + test[chosen_tests][key][0]}</span><span className="has-text-weight-bold">(DEX: </span>{test[chosen_tests][key][1]}) */}
                                </i>
                            </p>
                        </>
                        )
                    } else {
                        return (
                        <>
                            <p>
                                <b>
                                    <span style={{fontSize:'18px', borderStyle:'1px solid'}}>{key}</span>
                                </b>
                                <i>
                                    <span style={{paddingRight: "10px", fontSize: '15px'}}>{" - " + test[chosen_tests][key][0]}</span>
                                </i>
                            </p>
                        </>
                        )
                    }
                })}
                </div>
                <hr></hr>
                <div className="buttons is-responsive">
                    <button onClick={this.expand} className="is-link button">
                        {this.state.expandButton}
                        {this.state.expanded ? <Icon className="pl-1" path={mdiChevronUp} size={1}/> : <Icon className="pl-1" path={mdiChevronDown} size={1}/> }
                    </button>
                    {(() => {
                        if (this.props.chosen_test === "Constitutional"){
                            let trfLink = 
                                <a className="is-link button" href={consttrf} target="_blank" rel="noopener noreferrer">
                                    Test Request Form
                                    <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                </a>
                            if (this.props.chosen_type === "SOMATIC") {
                                trfLink = 
                                <a className="is-link button" href={somtrf} target="_blank" rel="noopener noreferrer">
                                    Test Request Form
                                    <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                </a>
                            }
                            return (
                                <>
                                    {trfLink}
                                    <a className="is-link button" href={dc} target="_blank" rel="noopener noreferrer">
                                        Consent Form
                                        <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                    </a>
                                </>
                            )
                        } else if (this.props.chosen_test.includes("Carrier")) {
                            if (this.props.chosen_test.includes("Individual")) {
                                return (
                                    <>
                                        <a className="is-link button" href={carrtrf} target="_blank" rel="noopener noreferrer">
                                            Test Request Form
                                            <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                        </a>
                                        <a className="is-link button" href={icc} target="_blank" rel="noopener noreferrer">
                                            Consent Form
                                            <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                        </a>
                                    </>
                                )
                            } else if (this.props.chosen_test.includes("Couple")) {
                                return (
                                    <>
                                        <a className="is-link button" href={carrtrf} target="_blank" rel="noopener noreferrer">
                                            Test Request Form
                                            <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                        </a>
                                        <a className="is-link button" href={ccc} target="_blank" rel="noopener noreferrer">
                                            Consent Form
                                            <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                        </a>
                                    </>
                                )
                            }
                        } else if (this.props.chosen_test.includes("Heredity")){
                            return (
                                <>
                                <a className="is-link button" href={hertrf} target="_blank" rel="noopener noreferrer">
                                    Test Request Form
                                    <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                </a>
                                <a className="is-link button" href={hrConsentf} target="_blank" rel="noopener noreferrer">
                                    Consent Form
                                    <Icon className="pl-1" path={mdiListBoxOutline} size={1}/>
                                </a>
                                </>
                            )
                        }
                    })()}
                </div>
                {/* Test Box Descriptions... */}
                <div className={"expandable " + (this.state.expanded ? "fade-in" : "is-hidden")}>
                    <div className="columns">
                        <div className="testbox-expand column">
                            <b className="testbox-column-title">Detailed Description</b>
                            <br></br>
                            <br></br>
                            {(() => {
                                if (this.props.chosen_test.includes("Carrier")) {
                                    if (this.props.chosen_test.includes("Individual")) {
                                        return (
                                            test.individualDescription
                                        )
                                    } else if (this.props.chosen_test.includes("Couple")) {
                                        return (
                                            test.coupleDescription
                                        )
                                    } 
                                } else {
                                    return (
                                        test.description
                                    )
                                }
                            })()}
                            <br></br>
                        </div>
                        <div className="testbox-expand column">
                            <b className="testbox-column-title">Sample Specifications</b>
                            <br></br>
                            <br></br>
                            {(() => {
                                if (this.props.to.includes('aneuploidy')){
                                    return
                                } else {
                                    return (
                                        <>
                                            <img className={"chartimg"} src={specChart} alt="Chart of specifications"></img>
                                            <br></br>
                                            <br></br>
                                        </>
                                    )
                                }
                            })()}
                            {test.sampleinfo}
                            <br></br>
                            {supplementaryInfo}
                        </div>
                    </div>
                </div>
            </div>
        </> : null
        )
    }


    render() {
        return (
            <>
                {this.fillDetails(this.props)}
            </>
        )
    }
}

export default TestBox
