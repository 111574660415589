import React from 'react'

import { Link as ScrollLink } from "react-scroll"
import { Link } from 'react-router-dom'

import TestHelp from "./TestHelp.js"

import '../CSS/NewHeroSection.css'
import chrIcon from "../../assets/images/chromosome.png"

class NewHeroSection extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			modalState : ' has-modal-off',
			popUpState : ' test-helper-container pop-up-off',
			videoLoaded: false
		}
		this.handleVideoLoaded = this.handleVideoLoaded.bind(this);
	}

	handleVideoLoaded () {
		this.setState({ videoLoaded: true });
	}

	toggleState(){
		if (this.state.modalState === ' has-modal-off'){
			this.setState({popUpState: ' test-helper-container pop-up-on'})
			this.setState({modalState: ' has-modal-on-lower-opacity'})
		} else {
			this.setState({popUpState: ' test-helper-container pop-up-off'})
			this.setState({modalState: ' has-modal-off'})
		}
	}

	render () {
		return (
			<>
				<TestHelp
					passedFunction = {this.toggleState.bind(this)}
					popUpState = {this.state.popUpState}
					modalState = {this.state.modalState}
				/>
				<div className="w-background upper-layer">
					<div className="hero-section-wrapper hero-background-img">
						<div className="siding chromosome-wrapper">
							<img src={chrIcon} alt="Chromosome" className="chromosome-img" />
						</div>
						<div className="new-hero-wrapper">
							<h1 className="siding has-text-dark is-size-3-mobile is-size-2-tablet is-size-1-desktop">
								{this.props.second}
							</h1>
							<div className="divider no-vis"></div>
							<h1 className="siding subtitle is-size-4-desktop is-size-5-tablet is-size-7-mobile">
								{this.props.third}
								<br></br>
								<br></br>
								{this.props.fourth}
								<br></br>
								<br></br>
								{this.props.fifth}
							</h1>
							<div className="siding-ys-and-left hero-buttons-container">
								<a href="#" onClick={this.toggleState.bind(this)} className="has-text-link-dark is-underlined hero-button is-size-4-desktop is-size-5-tablet is-size-7-mobile">
									Find The Right Test For You
								</a>
								<ScrollLink
										activeClass="is-selected"
										className="hero-button has-text-link-dark is-underlined is-size-4-desktop is-size-5-tablet is-size-7-mobile"
										to="brochures"
										spy={true}
										smooth={true}
										offset={-150}
										duration={800}>
											Download our Brochures
									</ScrollLink>
								<a href="/scheduling" className="hero-button has-text-link-dark is-underlined is-size-4-desktop is-size-5-tablet is-size-7-mobile">
									Schedule Consultation
								</a>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}



export default NewHeroSection
