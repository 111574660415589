import React from 'react';


// A generic component that takes props for the Purpose section
export const PurposeSection = ({ children }) => {
	return (
		<p><span className='has-text-weight-bold'>Purpose: </span>
			{children}
		</p>
	);
}

// A generic component that takes props for the Purpose section
export const PurposeSectionWithList = ({ text, listItems }) => {
	return (
		<>
			<p><span className='has-text-weight-bold'>Purpose: </span>
				{text}
			</p>
			<div className='content'>
				<ul>
					{listItems.map((item, index) => <li key={index}>{item}</li>)}
				</ul>
			</div>
		</>
	);
}

export const TextWithList = ({ text, listItems }) => {
	return (
		<>
			{text}
			<div className='content'>
				<ul>
					{listItems.map((item, index) => <li key={index}>{item}</li>)}
				</ul>
			</div>
		</>
	);
}


const renderListItems = (items) => {
	return items.map((item, index) => {
		if (Array.isArray(item)) {
			return (
				<li key={index} style={{ listStyleType: 'none' }}>
					<ul style={{ paddingLeft: '20px' }}>
						{renderListItems(item)}
					</ul>
				</li>
			);
		}
		return <li key={index}>{item}</li>;
	});
};

export const ListWithSubList = ({ text, listItems }) => {
	return (
		<>
			{text}
			<div className='content'>
				<ul>
					{renderListItems(listItems)}
				</ul>
			</div>
		</>
	);
};


// A generic component that takes props for the Sample Types section
export const SampleTypesSection = ({ children }) => {
	return (
		<>
			<p><span className='has-text-weight-bold'>Sample Types: </span>
				{children}
			</p>
		</>
	);
}

// A generic component that takes props for the Advantage section
export const AdvantageSection = ({ children }) => {
	return (
			<p><span className='has-text-weight-bold'>The Praxis Genomics Advantage: </span>
				{children}
			</p>
	);
}

// A generic component that takes props for the Limitations section
export const LimitationsSection = ({ children }) => {
	return (
			<p><span className='has-text-weight-bold'>Limitations of the method: </span>
				{children}
			</p>
	);
}

export const TechnologySection = ({ children }) => {
	return (
		<p><span className='has-text-weight-bold'>The Praxis Genomics Technology: </span>
			{children}
		</p>
	);
}

export const FunctionalTestingSection = ({ children }) => {
	return (
		<p><span className='has-text-weight-bold'>The importance of functional testing: </span>
			{children}
		</p>
	);
}

export const EasyReportsSection = ({ children }) => {
	return (
		<p><span className='has-text-weight-bold'>Easy to read reports: </span>
			{children}
		</p>
	);
}

export const FinancialSection = ({ children }) => {
	return (
		<p><span className='has-text-weight-bold'>Financial considerations: </span>
			{children}
		</p>
	);
}

export const MethodologySection = ({ children }) => {
	return (
		<p><span className='has-text-weight-bold'>Methodology: </span>
			{children}
		</p>
	);
}