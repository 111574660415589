import React from 'react'
import '../CSS/NavBar.css'

import logo from "../../assets/images/Logotag3.png"
import { Link } from 'react-router-dom'
import { animateScroll as scroll} from "react-scroll"

class SubNavBar extends React.Component{

    constructor(props) {
        super(props)
        this.state = {
            NavBarIsActive : ""
        }
    }

    toggleState(){
        if (this.state.NavBarIsActive === ' is-active'){
            this.setState({NavBarIsActive: ''})
        } else {
            this.setState({NavBarIsActive: ' is-active'})
        }
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    };

    render() {
        return (
            <header className="hero is-info is-medium is-bold is-fixed is-above-all">
                <div className="hero-head">
                    <nav className="navbar">
                        <div className="container">
                            <div className="navbar-brand">
                                <Link className="navbar-item" to="/">
                                    <img
                                         src={logo}
                                         alt="Logo"
                                    />
                                </Link>
                                <span className={"navbar-burger burger" + this.state.NavBarIsActive} onClick={this.toggleState.bind(this)}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                </span>
                            </div>
                            <div id="navbarMenu" className={"navbar-menu" + this.state.NavBarIsActive}>
                                <div className="navbar-end">
                                    <div className="tabs is-right">
                                        <ul>
                                            <li>
                                                <Link
                                                    className="has-text-weight-semibold"
                                                    to="/"
                                                >
                                                Home
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="has-text-weight-semibold"
                                                    to="/test-list"
                                                >
                                                Tests
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="has-text-weight-semibold"
                                                    to="/"
                                                >
                                                About Us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="has-text-weight-semibold"
                                                    to="/"
                                                >
                                                News
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="has-text-weight-semibold"
                                                    to="/"
                                                >
                                                Technology
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    className="has-text-weight-semibold"
                                                    to="/"
                                                >
                                                Contact Us
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        )
    }

}

export default SubNavBar
