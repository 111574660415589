import React from 'react'
import SubNavBar from './SubNavBar'

const NotFoundPage = () => {
    return (
		<>
			<SubNavBar />
			<section className="hero is-fullheight is-primary">
				<div className="hero-body">
					<div className="container has-text-centered">
						<p className="title">
							404 Not Found
						</p>
						<p className="subtitle">
							The page you are looking for does not exist.
						</p>
					</div>
				</div>
			</section>
		</>
    );
}

export default NotFoundPage;
