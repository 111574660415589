import React from 'react'
import '../CSS/Stripes.css'


function Stripes(props) {
    return (
        <div id={props.id} aria-hidden={'true'} style={{ height: props.height }}>
            <span></span>
        </div>
    )
}

export default Stripes