import React from 'react'
import HeroSection from '../components/HeroSection'
import SubHero from '../components/SubHero'

import TechnologySection from '../components/TechnologySection'
import ServicesSection from '../components/ServicesSection'
import IntroSection from '../components/IntroSection'

import NavBar from "../components/NavBar"
import Contact from "../components/Contact"
import SimpleFooter from "../components/SimpleFooter"
import CredationSection from "../components/CredationSection"
import TeamSection from "../components/OurTeamSection"
import AdvisoryBoard from "../components/AboutSection"
import NewsSection from "../components/NewsSection"
import NewHeroSection from '../components/NewHeroSection'

class Home extends React.Component {
	render() {
		return (
			<>
				<NavBar />
				{/* <HeroSection
					first="Finding diagnoses, Finding cures"
					second={<>Comprehensive Genetic Testing <br></br> + <br></br> Counseling Services</>}
					third="Maximum sensitivity and specificity through cutting-edge technologies and methodologies" 
					fourth="High complexity genome testing made accessible"
				/> */}
				<NewHeroSection 
					first="Finding diagnoses, Finding cures"
					second={<>Comprehensive Genetic <br></br> Testing & Consulation</>}
					third="Despite costly and extensive testing, nearly 50% of patients never receive a molecular diagnosis. Similarly, less than 10% of cancer patients have their malignancies treated based on the specific molecular alterations of their illness." 
					fourth="The purpose of Praxis Genomics is to provide a molecular level diagnosis for difficult to solve inherited disorders and provide deep molecular analysis of cancers, driving a personalized therapeutic approach for people with malignancies."
					fifth="We accomplish these goals by combining Bionano Optical Genome Mapping technology with Whole Genome and Transcriptome Sequencing. Praxis Genomics is the only provider offering this combination of testing services in the world and can claim an unsurpassed percentage of cases with successful molecular diagnosis."
				/>
				<SubHero />
				<ServicesSection />
				<TeamSection />
				<AdvisoryBoard />
				<NewsSection />
				<TechnologySection />
				<Contact />
				<CredationSection />
				<SimpleFooter />
			</>
		)
	}
}

export default Home
