import React from 'react'
import { Link } from 'react-router-dom'
import shipDec from "../../assets/code-info/ShippersDeclaration.pdf"
import commInvoice from "../../assets/forms/CommercialInvoiceTemplate.pdf"
import { PurposeSection, PurposeSectionWithList, SampleTypesSection, AdvantageSection, LimitationsSection, TechnologySection, FunctionalTestingSection, EasyReportsSection, FinancialSection, TextWithList, MethodologySection, ListWithSubList } from '../components/TestBox/TestBoxIntroAndDescriptionComponents.js'


/////////////////////////////////////////////////TEST CODES ////////////////////////////////////////////////
const basicExomeTests = {
	"SRG501": ["Basic Exome, Proband", "Z00PA"],
	"SRG502": ["Basic Exome, Duo"],
	"SRG503": ["Basic Exome, Trio", "Z00PC"],
	"SRG504": ["Basic Exome, 4 Sample", "Z00PB"],
	"SRG505": ["Expanded Exome Upgrade per Sample"],
	"SRG506": ["Basic Genome Upgrade per Sample"]
}

const expandedExomeTests = {
	"SRG507": ["Expanded Exome, Proband Only", "Z00PA"],
	"SRG508": ["Expanded Exome, Duo"],
	"SRG509": ["Expanded Exome, Trio", "Z00PC"],
	"SRG510": ["Expanded Exome, 4 Sample", "Z00PB"],
	"SRG511": ["Whole Genome Upgrade per Sample"]
}

const familialTests = {
	"KFV001": ["Targeted assessment for 1 variant in one person"]
}

const heredityTests = {
	"SRG301": ["Mitochondrial and Y Haplogroup Analysis"],
	"SRG302": ["Relatedness Testing Duo"],
	"SRG303": ["Relatedness Testing Trio"]
}

const saphyrConstitutional = {
	"OGM001": ["Optical Genome Mapping Proband", " Z00FM"],
	"OGM002": ["Optical Genome Mapping Duo", "Z00FN"],
	"OGM003": ["Optical Genome Mapping Trio", "Z00FP"],
	"OGM004": ["Optical Genome Mapping 4 Sample", "Z00G6"]
}

const saphyrCarrierIndividual = {
	"OGM005": ["Optical Genome Mapping Carrier Individual", "Z00FQ"]
}

const saphyrCarrierCouple = {
	"OGM006": ["Optical Genome Mapping Carrier Couple", "Z00FR"]
}

const lr_wholeGenSeqConstitutional = {
	"LRS001": ["Long Read Sequencing", ""]
}

const methylomeWGSConstitutional = {
	"LRS101": ["Long Read Methylome", ""]
}

const aneuploidyConstitutional= {
	"SRG801": ["Aneuploidy Testing", ""] 
}

const wholeGenSeqConstitutional = {
	"SRG001": ["Whole Genome Sequencing, Proband", "Z00FK"],
	"SRG002": ["Whole Genome Sequencing, Duo", "Z00FJ"],
	"SRG003": ["Whole Genome Sequencing, Trio", "Z00FI"],
	"SRG004": ["Whole Genome Sequencing, 4 Sample", "Z00G5"]
}

const wholeGenSeqCarrierIndividual = {
	"SRG005": ["Whole Genome Sequencing, Carrier Individual", "Z00FH"]
}

const wholeGenSeqCarrierCouple = {
	"SRG006": ["Whole Genome Sequencing, Carrier Couple", "Z00FG"]
}

const combinationConstitutional = {
	"PRX001": ["Combined Optical Genome Mapping and Whole Genome Sequencing Analysis, Proband", "Z00FF"],
	"PRX002": ["Combined Optical Genome Mapping and Whole Genome Sequencing Analysis, Duo", "Z00F1"],
	"PRX003": ["Combined Optical Genome Mapping and Whole Genome Sequencing Analysis, Trio", "Z00FE"],
	"PRX004": ["Combined Optical Genome Mapping and Whole Genome Sequencing Analysis, 4 Sample", "Z00G8"],
	"PRX007": ["Combined Optical Genome Mapping, Whole Genome Sequencing and Transcriptome Analysis, Proband", "Z00G7"],
	"PRX008": ["Combined Optical Genome Mapping, Whole Genome Sequencing and Transcriptome Analysis, Duo", "Z00G9"],
	"PRX009": ["Combined Optical Genome Mapping, Whole Genome Sequencing and Transcriptome Analysis, Trio", "Z00GA"],
	"PRX010": ["Combined Optical Genome Mapping, Whole Genome Sequencing and Transcriptome Analysis, 4 Sample", "Z00GB"],
	"PRX101": ["Combined Optical Genome Mapping and External Data Analysis, Proband", "Z00EY"],
	"PRX102": ["Combined Optical Genome Mapping and External Data Analysis, Duo", "Z00EX"],
	"PRX103": ["Combined Optical Genome Mapping and External Data Analysis, Trio", "Z00GC"],
	"PRX104": ["Combined Optical Genome Mapping and External Data Analysis, 4 Sample", "Z00GD"]

}

const combinationCarrierIndividual = {
	"PRX005": ["Combined Optical Genome Mapping and Whole Genome Sequencing Analysis, Carrier Individual", "Z00F0"]
}

const combinationCarrierCouple = {
	"PRX006": ["Combined Optical Genome Mapping and Whole Genome Sequencing Analysis, Carrier Couple", "Z00EZ"]
}

const transcriptomeTests = {
	"SRT401": ["Transcriptome Proband", "Z00GE"],
	"SRT402": ["Transcriptome Duo", "Z00GF"],
	"SRT403": ["Transcriptome Trio", "Z00GG"],
	"SRT404": ["Transcriptome 4 Sample", "Z00GH"]
}

const extWholeGenTests = {
	"SRG101": ["External Data Analysis, Proband", "Z00GY"],
	"SRG102": ["External Data Analysis, Duo", "Z00GZ"],
	"SRG103": ["External Data Analysis, Trio", "Z00H0"],
	"SRG104": ["External Data Analysis, 4 Sample", "Z00H1"]
}

const FSHDTests = {
	"OGM201": ["FSHD1 Proband", "Z00FD"],
	"OGM202": ["FSHD1 Due", "Z00FC"],
	"OGM203": ["FSHD1 Trio", "Z00FB"],
	"OGM204": ["FSHD1 4 Sample", "Z00FA"],
	"SRG201": ["FSHD2 Testing Proband", "Z00G1"],
	"SRG202": ["FSHD2 Testing Duo", "Z00G3"],
	"SRG203": ["FSHD2 Testing Trio", "Z00G4"],
	"SRG204": ["FSHD2 Testing 4 Sample", "Z00G2"],
	"PRX201": ["FSHD1 and 2 Testing, Proband", "Z00FX"],
	"PRX202": ["FSHD1 and 2 Testing, Duo", "Z00FY"],
	"PRX203": ["FSHD1 and 2 Testing, Trio", "Z00FZ"],
	"PRX204": ["FSHD1 and 2 Testing, 4 Sample", "Z00G0"]
}

const repeatExpansionTests = {
	"OGM101": ["Repeat Sizing Proband", "Z00GT"],
	"OGM102": ["Repeat Sizing Duo", "Z00GU"],
	"OGM103": ["Repeat Sizing Trio", "Z00GV"],
	"OGM104": ["Repeat Sizing 4 Sample", "Z00GW"]
}

const somaticOGMTests = {
	"OGM601": ["OGM Cancer, One Normal and One Malignant Specimen", "Z00RR"],
	"OGM602": ["OGM Cancer, Additional Specimen"]
}

const somaticCombinationTests = {
	"PRX601": ["OGM+WGS Cancer, One Normal and One Malignant Specimen", "Z00RS"],
	"PRX602": ["OGM+WGS Cancer, Additional Specimen"]
}

const somaticTranscriptomeTests = {
	"SRT701": ["RNA Cancer, One Normal and One Malignant Specimen", "Z00RQ"],
	"SRT702": ["RNA Cancer, Additional Specimen"]
}

const somaticWGSTests = {
	"SRG601": ["WGS Cancer, One Normal and One Malignant Specimen", "Z00RP"],
	"SRG602": ["WGS Cancer, Additional Specimen"]
}

// Test Category Intros and Descriptions
export const constitutionalIntro =
	<>
		<h2 className="is-size-2 is-size-4-mobile has-text-weight-bold">Constitutional Diagnostic Testing</h2>
		<br></br>
		<p>
			<span className='has-text-weight-bold'>Reason to Test:</span> This category of testing aims to establish the molecular cause of
			genetic disorders that people could transfer to their children. The results can be used to
			guide therapy and prognostication of disease outcomes. This test is for people who already
			show symptoms of a disorder. We offer carrier testing for people who worry about having
			variants that would cause them develop disease later in life. The reason for this distinction is
			that diagnostic and carrier testing data requires different types of analysis and reporting.
		</p>
	</>

export const constitutionalDiagnosticDescription = 
	<>
		<br></br>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body
			carry the same disease causing DNA variant(s). This is the reason that these disorders can
			be diagnosed from different sample types such as blood, saliva, buccal swab, tissue biopsies
			from any organ or cultured cells. OGM testing requires fresh blood or tissue biopsy.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			 To diagnose the patient's problem, we identify all types of
			variants over the entire genome of an individual. These include nuclear and mitochondrial
			single nucleotide polymorphisms and small insertions-deletions, repeat expansions (Fragile
			X syndrome), contractions (FSHD), and structural variants of all size and type including those
			that do not result in loss or gain of genetic material such as inversions and translocations.
		</AdvantageSection>
		<br></br>
		<TechnologySection>
			 We can identify all types of variants because we use Short
			Read Whole Genome Sequencing (SRG) and Optical Genome Mapping (OGM) together. The
			complementary strength of the two methodologies, and the fact that the two datasets are
			analyzed together by the same medical director, results in sensitivity and accuracy that other
			laboratories cannot match.
		</TechnologySection>
		<FunctionalTestingSection>
			Variants identified by SRG and OGM often have not
			been previously described and it is important to show that they indeed cause the patient's
			symptoms. Transcriptome (SRT) analysis provides such information based on the quantity
			and quality of the transcripts made from the affected genes. 
		</FunctionalTestingSection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. An
			easy-to-understand, concise report is issued with a clear diagnosis, allowing prognostication
			and choosing the best possible therapy for the patient.
		</EasyReportsSection>
		<br></br>
		<FinancialSection>
			Although we recommend that (SRG) and (OGM) tests be performed
			simultaneously they can also be performed individually or sequentially. We also offer a
			graded reporting scheme consisting of a Basic Exome, Expanded Exome and Whole Genome
			analysis from the same genomic dataset at a reduced cost. If the cause of the patient's
			condition cannot be established based on currently available information, we provide
			reanalysis of the data one year after the testing was done.
		</FinancialSection> 
		<br></br>
	</>

export const indCarrierGeneralDescription =
	<>
		<h2 className="is-size-2 is-size-4-mobile has-text-weight-bold">Constitutional Carrier Individual Testing</h2>
		<br></br>
		<p>
			<span className='has-text-weight-bold'>Reason to Test:</span> Carrier testing is offered for individuals and couples. The two offerings
			are significantly different in both their indications and their use. The carrier test for
			individuals detects and reports all pathogenic variants that can either make a healthy person
			develop a disease at a later time or can potentially result in an affected child.
		</p>
	</>

export const coupCarrierGeneralDescription =
	<>
		<h2 className="is-size-2 is-size-4-mobile has-text-weight-bold">Constitutional Carrier Couple Testing</h2>
		<br></br>
		<p>
			<span className='has-text-weight-bold'>Reason to Test:</span> Carrier testing is offered for individuals and couples. The two offerings
			are significantly different in both their indications and their use. The carrier testing for couples focuses on the reproductive success
			of the specific couple ordering the test. A single report is issued to the couple that only
			reports variants that both partners carry and that can potentially result in an affected child.
		</p>
	</>

export const CarrierCoupleDescription =
	<>
		<br></br>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body
			carry the same disease causing DNA variant(s). This is the reason that these disorders can
			be diagnosed from different sample types such as blood, saliva, buccal swab, tissue biopsies
			from any organ or cultured cells. OGM testing requires fresh blood or tissue biopsy.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			To diagnose the patient's problem, we identify all types of
			variants over the entire genome of an individual. These include nuclear and mitochondrial
			single nucleotide polymorphisms and small insertions-deletions, repeat expansions (Fragile
			X syndrome), contractions (FSHD), and structural variants of all size and type including those
			that do not result in loss or gain of genetic material such as inversions and translocations.
			These could be an important cause of infertility or repeated pregnancy losses.
			<br></br>
			<br></br>
			Traditionally, carrier testing is done in a targeted manner focusing on variants known to be
			prevalent in a certain populations. Many families with a very strong history of recessive
			disorders, cancer or dementias have no genetic diagnosis of their underlying condition. This
			means that no preventive measures can be taken such as preimplantation testing or
			screening for specific types of malignancies. It also means that people cannot prepare to
			deal with conditions for which prevention in not yet available.
		</AdvantageSection>
		<br></br>
		<TechnologySection>
			We can identify all types of variants because we use Short
			Read Whole Genome Sequencing (SRG) and Optical Genome Mapping (OGM) together. The
			complementary strength of the two methodologies, and the fact that the two datasets are
			analyzed together by the same medical director, results in sensitivity and accuracy that other
			laboratories cannot match.
		</TechnologySection>
		<br></br>
		<FunctionalTestingSection> 
			Variants identified by SRG and OGM often have not
			been previously described and it is important to show that they indeed pathogenic.
			Transcriptome (SRT) analysis provides such information based on the quantity and quality of
			the transcripts made from the affected genes. 
		</FunctionalTestingSection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. For
			individuals we report on conditions that would severely affect their or their children's quality
			of life and conditions of concern irrespective of their severity. Couples receive a single report
			that focuses on variants that would severely affect their child.
		</EasyReportsSection>
	</>

export const CarrierIndividualDescription =
	<>
		<br></br>
		<SampleTypesSection> 
			With rare exceptions, in constitutional disorders all cells in the patient's body
			carry the same disease causing DNA variant(s). This is the reason that these disorders can
			be diagnosed from different sample types such as blood, saliva, buccal swab, tissue biopsies
			from any organ or cultured cells. OGM testing requires fresh blood or tissue biopsy.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			To diagnose the patient's problem, we identify all types of
			variants over the entire genome of an individual. These include nuclear and mitochondrial
			single nucleotide polymorphisms and small insertions-deletions, repeat expansions (Fragile
			X syndrome), contractions (FSHD), and structural variants of all size and type including those
			that do not result in loss or gain of genetic material such as inversions and translocations.
			These could be an important cause of infertility or repeated pregnancy losses.
			<br></br>
			<br></br>
			Traditionally, carrier testing is done in a targeted manner focusing on variants known to be
			prevalent in a certain populations. Many families with a very strong history of recessive
			disorders, cancer or dementias have no genetic diagnosis of their underlying condition. This
			means that no preventive measures can be taken such as preimplantation testing or
			screening for specific types of malignancies. It also means that people cannot prepare to
			deal with conditions for which prevention in not yet available.
		</AdvantageSection>
		<br></br>
		<TechnologySection>
			We can identify all types of variants because we use Short
			Read Whole Genome Sequencing (SRG) and Optical Genome Mapping (OGM) together. The
			complementary strength of the two methodologies, and the fact that the two datasets are
			analyzed together by the same medical director, results in sensitivity and accuracy that other
			laboratories cannot match.
		</TechnologySection>
		<br></br>
		<FunctionalTestingSection>
			Variants identified by SRG and OGM often have not
			been previously described and it is important to show that they indeed pathogenic.
			Transcriptome (SRT) analysis provides such information based on the quantity and quality of
			the transcripts made from the affected genes. 
		</FunctionalTestingSection>
		<br></br>
		<EasyReportsSection>
				All this information is filtered and interpreted by a medical doctor. For
			individuals we report on conditions that would severely affect their or their children's quality
			of life and conditions of concern irrespective of their severity. Couples receive a single report
			that focuses on variants that would severely affect their child.
		</EasyReportsSection>
	</>

export const heredityIntro =
	<>
		<h2 className="is-size-2 is-size-4-mobile has-text-weight-bold">Constitutional Heredity & Relatedness Testing</h2>
		<br></br>
		<p><span className='has-text-weight-bold'>Reason to Test:</span> Heredity testing aims to establish the paternal and maternal origins of
		an individual based on analysis of the single nucleotide variants (SNVs) in the Y chromosome and the mitochondrial genome, respectively. The mitochondrial genome and
		the Y chromosome are like molecular diaries. Every few generations, a variant is established
		and thus variants introduced over thousands of generations take us back to the molecular
		Adam and Eve.
		</p>
		<br></br>
		<p>
		Relatedness Testing aims to establish the degree of relationships between two individuals.
		This type of testing relies on the variants that are shared between individuals on the
		autosomes (Chromosomes 1 - 22). It will provide a reliable estimate of the degree of
		relatedness between two individuals up to five degrees of separation.
		</p>
	</>

export const heredityDescription =
	<>
		<br></br>
		<SampleTypesSection>
			All cells in the patient's body carry the same DNA variant(s) used in these
			tests. For this reason the tests can be performed on different sample types such as saliva,
			buccal swab, blood, tissue biopsies from any organ or cultured cells. OGM testing requires
			fresh blood or tissue biopsy. Praxis Genomics has special expertise to perform these test on
			archaic samples as well.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Commonly used heredity tests take a targeted approach in
			identifying phylogenetic origin. They analyze the sample for the presence of a set of variants
			that have been well described in many people and do not look for personal variants. If they
			identify variants that have not been previously seen, they do not report them until they find
			them in at least one other person being tested. We believe that identifying and reporting
			variants throughout the entire nonrepetitive section of the Y-chromosome and mitochondrial
			genome is important. Therefore we analyze all samples using whole genome sequencing
			(SRG). This information can help you identify relatives that you share a common ancestor
			with and will allow determination of the time to the most recent common ancestor.
		</AdvantageSection>
		<br></br>
		<EasyReportsSection>
			All the information is filtered and interpreted by a medical doctor who
			has published seminal articles in archeogenetics of ancient dynasties. An easy-to-understand, concise report is issued with a clear description of genetic origins and the
			degree of relatedness between selected individuals.
		</EasyReportsSection>
	</>

export const somaticTestingIntro =
	<>
		<h2 className="is-size-2 is-size-4-mobile has-text-weight-bold">Somatic Diagnostic Testing</h2>
		<br></br>
		<p>
			<span className='has-text-weight-bold'>Reason to Test: </span>
			This category of testing aims to establish the molecular cause of
			disorders that are manifesting only in a subset of the patient's cells. These include all types
			of malignancies as well as cases of mosaicism and chimerism. The results can be used for
			diagnostic, therapeutic and prognostication purposes. These tests are for situations where
			the disease has already manifested itself. If predisposition to a certain malignant condition
			is suspected, one should use our
			<Link
				onClick={() => window.location.reload()}
				to={{
					pathname: "/test-list",
					state: {
						chosen_type: "CONSTITUTIONAL",
						chosen_test: "Constitutional",
						pre_testing: "DISEASE"
					},
				}}
			> constitutional diagnostic </Link>
			or 
			<Link
				onClick={() => window.location.reload()}
				to={{
					pathname: "/test-list",
					state: {
						chosen_type: "CONSTITUTIONAL",
						chosen_test: "Carrier Individual",
						pre_testing: "DISEASE"
					},
				}}
			> carrier tests</Link>.
		</p>
	</>

export const somaticTestingDescription =
	<>
		<br></br>
		<SampleTypesSection>
			Since in these cases the genetic abnormalities are only present in a subset of
			the patient's cells, the analysis is performed by comparison of the genetic content of the
			patient's normal and abnormal cells. Acceptable normal samples are blood, buccal swab,
			saliva, or tissue biopsies from unaffected areas. The abnormal cells need to derive from the
			affected organ or region of the body and can be cells or tissue samples. Both frozen and
			fixed samples (FFPE) are accepted. However, OGM testing does not work on fixed tissues.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Many hundreds of our genes are associated with the
			regulation of cell division, differentiation of various cell lineages and cell death. If balancing
			of these processes is compromised because of mutations in the genes involved, various
			malignancies arise that can threaten the function and in some cases the survival of the
			individual. Although specific conditions are often associated with specific mutations
			allowing targeted testing, in many cases the cause of the malignancy is unique to the
			individual or is due to many mutations necessitating genome-wide testing for all types of
			variants. The goal is to select the most appropriate therapeutic approach for the patient's
			condition to prevent relapse following treatment. If relapse occurs, testing is performed to
			identify alternative therapeutic approaches. 
		</AdvantageSection>
		<br></br>
		<TechnologySection>
			We can identify all types of variants because we use Short
			Read Whole Genome Sequencing (SRG) and Optical Genome Mapping (OGM) together. The
			complementary strength of the two methodologies, and the fact that the two datasets are
			analyzed together by the same medical director, results in sensitivity and accuracy that other
			laboratories cannot match. 
		</TechnologySection>
		<br></br>
		<FunctionalTestingSection>
			Variants identified by SRG and OGM often have not
			been previously described and it is important to show that they indeed cause the patient's
			symptoms. Transcriptome (SRT) analysis provides such information based on the quantity
			and quality of the transcripts made from the affected genes. 
		</FunctionalTestingSection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor
			board certified in anatomic and molecular genetic pathology. An easy-to-understand, concise
			report is issued with a clear diagnosis, allowing prognostication and choosing the best
			possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
	</>

// FSHD Testing Box
const fshdIntro = 
	<>
		<PurposeSection>
			This test is to diagnose FSHD. Optical genome mapping can determine whether the
			patient has FSHD1 and whole genome sequencing can diagnose FSHD2. At Praxis Genomics, we offer
			testing for FSHD1 and FSHD2 individually, as well as in combination.
		</PurposeSection>
	</>

export const fshd1Description =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body carry the
			same disease causing DNA variant(s). This is the reason that FSHD2 can be diagnosed from different
			sample types such as blood, saliva, buccal swab, tissue biopsies from any organ or cultured cells.
			However, FSHD1 testing performed using optical genome mapping requires fresh blood.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Praxis Genomics is the only laboratory in the USA that currently offers
			testing for FSHD1 and FSHD2 individually, as well as in combination. FSHD is a muscular dystrophy with a
			characteristic clinical presentation and very complex molecular pathogenesis. Rather than being caused
			by a single mutation, it arises from the combined effects of several genetic characteristics of the
			individual. In other words, changes that would cause disease in one person may not cause any or will
			cause only mild symptoms in another. This variation can be observed even within affected families.
			Prognostication of the disease and providing reproductive recommendations for affected individuals
			requires a precise genetic evaluation of the factors involved. Two main forms of the disease are
			described: FSHD1 and FSHD2. FSHD1 is the more common form, representing about 95 percent of all
			cases, while FSHD2 is suspected when the patient comes up negative for causes of FSHD1. Currently no
			single technology can characterize all the factors involved in pathogenesis for both types of FSHD.
			Optical Genome Mapping (OGM) can determine whether the patient has FSHD1 and whole genome
			sequencing can diagnose FSHD2.
		</AdvantageSection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a board certified surgical and
			molecular genetic pathologist. An easy-to-understand, concise report is issued with a clear diagnosis,
			allowing prognostication and choosing the best possible therapy for the patient. The FSHD1 report will
			specify the number of D4Z4 repeats on both chromosome 4 and 10 and will provide the DUX4 allele type
			(stable, 4qA or unstable, 4qB) associated with these repeats. FSHD1 is diagnosed if on chromosome 4
			the repeat number is equal or less than 10 and DUX4 allele is stable (4qA). The FSHD2 test report will
			provide a list of mutations, both single nucleotide changes and deletions that would cause disease in the
			presence of normal repeat numbers
		</EasyReportsSection>
	</>

// Repeat expansion 
const repeatIntro = 
	<>
		<PurposeSection>
			Repeat expansions can be identified using Whole Genome Sequencing, but their size
			cannot be precisely determined. We offer this test based on optical genome mapping to accurately size
			repeat expansions greater than 500bp in length.
		</PurposeSection>
	</>

const repeatDescription =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body carry the
			same disease causing DNA variant(s). However, repeat sizing performed using optical genome mapping
			requires fresh blood.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Repeat expansions cause a diverse and ever-expanding number of
			diseases. Their manifestations are diverse not only because they affect different genes, but also because
			they can arise in different regions of genes. The longer the repeat expansion, the more pronounced the
			associated clinical phenotype. Repeat expansions can be identified using Whole Genome Sequencing. For
			accurate sizing of repeat expansions greater than 500bp, Optical Genome Mapping is the methodology of
			choice. OGM analyzes 250-300,000 bp long individual DNA molecules and can thus provide accurate
			measurements even for the biggest repeats such as those seen in Myotonic Dystrophy type 1, ALS
			(C9ORF72 associated), or Fragile X syndrome. Other disorders where OGM based repeat mapping can be
			helpful are Friedrich Ataxia, SCA8 (ATXN8), SCA2 (ATXN2), SCA10 (ATXN10), SCA7 (ATXN7), SCA36
			(NOP56), SCA31 (BEAN31), FRA12A (DIP2B), and Familial Adult Myoclonic Epilepsy type 1 (SMAD12).
		</AdvantageSection>
		<br></br>
		<FinancialSection>
			It is important to note that OGM can size multiple repeats in a single
			experiment for a single charge.
		</FinancialSection>
		<br></br>
		<EasyReportsSection>
			The report will focus on the repeat expansion of interest, but if requested all repeat
			regions mentioned above can be commented on.
		</EasyReportsSection>
		<br></br>
		<LimitationsSection>
			This sizing approach only works for genes where the expansion is expected to
			add more than 500bp to the normal length of the repeat.
		</LimitationsSection>
	</>

// Individual Test Descriptions
const knownFamilialIntro = "This technique is used to assess whether a variant known to cause disease in a family is present in other family members besides the proband tested. This is accomplished by targeted enrichment of the specific area from other family members and sequencing that region to test for the presence of the familial variant. This method is for single nucleotide variants (SNVs) and small insertions/deletions (InDels) only."
const knownFamilialDescription =
	<>
		<p>
			Region of interest is amplified using PCR and sequenced using next generation sequencing.
		</p>
	</>

// CG OGM OGM ; PLA 0264U
const ogm_cg_intro = 
	<>
		<PurposeSectionWithList 
		text = "Optical Genome Mapping, (OGM) is for the evaluation of large-scale changes in the DNA that can cause heritable disorders of any kind. Changes that are detected by OGM are the following:"
		listItems={[
			"Intra- and inter- chromosomal translocations both balanced and unbalanced",
			"Inversions",
			"Complex chromosomal rearrangements",
			"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD)",
			"Tandem repeat expansions (Fragile X, Myotonic Dystrophy 1 and 2, C9ORF72, and others)"
		]}
		/>
	</>

const ogm_cg_description = 
	<>
		<SampleTypesSection>
			All cells in the patient's body carry the same DNA variant(s) detected in this test. However,
			OGM requires extraction of ultrahigh molecular weight DNA and because of this requirement testing can
			be performed on either fresh blood, cultured cells or tissue biopsy. Saliva, buccal swab or formalin fixed
			samples cannot be used for OGM.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Our laboratory is one of a few laboratories performing OGM as a clinical
			test in the world. The method is based on barcoding high molecular weight individual DNA molecules,
			200,000 base pair-long or longer. Each DNA fragment has a unique barcode pattern depending on its
			sequence, that partially overlaps with the pattern of other fragments. From these partially overlapping
			long DNA fragments, an entire barcoded genome can be assembled. Comparison of this assembly with a
			known reference barcode pattern calculated based on the reference sequence can detect increased
			distances between bars or altered order of bars, that correspond to altered genomic architecture. These
			types of changes can have major functional consequences and thus disease causing potential and can be
			identified reliably with Optical Genome Mapping only.
		</AdvantageSection>
		<br></br>
		<LimitationsSection>
			The barcode is established by attachment of fluorescent molecules to DNA in
			a sequence specific manner. The bars are spaced on average about 1,000bp apart from each other, but
			the exact distance varies depending on the region. For this reason, the resolution of this method is about
			1,000 bp in most areas, but there are some areas where it is higher or lower. The method is best
			performed in conjunction with whole genome sequencing which allows precise identification of
			breakpoints associated with structural variants.
		</LimitationsSection>
	</>

// OGM Carrier Individual 
const ogm_ci_intro = 
	<>
		<PurposeSectionWithList 
		text = "OGM is for the evaluation of large-scale changes in the DNA that can cause heritable disorders of any kind including those predisposing to malignancies, infertility and repeated pregnancy losses. Changes that are detected by OGM are the following:"
		listItems={[
			"Intra- and inter- chromosomal translocations both balanced and unbalanced",
			"Inversions",
			"Complex chromosomal rearrangements",
			"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD)",
			"Tandem repeat expansions (Fragile X, Myotonic Dystrophy 1 and 2, C9ORF72, and others)"
		]}
		/>
	</>


const ogm_ci_description = 
	<>
		<SampleTypesSection>
			All cells in the patient's body carry the same DNA variant(s) detected in this test. However,
			OGM requires extraction of ultrahigh molecular weight DNA and because of this requirement testing can
			be performed on either fresh blood, cultured cells or tissue biopsy. Saliva, buccal swab or formalin fixed
			samples cannot be used for OGM.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Our laboratory is one of a few laboratories performing OGM as a clinical 
			test in the world and the only one offering it for carrier testing. The method is based on barcoding
			high molecular weight individual DNA molecules, 200,000 base pair-long or longer. Each DNA fragment 
			has a unique barcode pattern depending on its sequence, that partially overlaps with the pattern of other
			fragments. From these partially overlapping long DNA fragments, an entire barcoded genome can be assembled. 
			Comparison of this assembly with a known reference barcode pattern calculated based on the reference sequence
			can detect increased distances between bars or altered order of bars, that correspond to altered genomic 
			architecture. These types of changes can have major functional consequences and thus disease causing 
			potential and can be identified reliably with Optical Genome Mapping only.
		</AdvantageSection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor.
			For individuals we report on conditions that would severely affect their or their 
			children's quality of life and conditions of concern irrespective of their severity. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection>
			The barcode is established by attachment of fluorescent molecules to DNA in
			a sequence specific manner. The bars are spaced on average about 1,000bp apart from each other, but
			the exact distance varies depending on the region. For this reason, the resolution of this method is about
			1,000 bp in most areas, but there are some areas where it is higher or lower. The method is best
			performed in conjunction with whole genome sequencing which allows precise identification of
			breakpoints associated with structural variants.
		</LimitationsSection>
	</>

// OGM Couple Individual 
const ogm_cc_intro = 
	<>
		<PurposeSectionWithList 
		text = "OGM is for the evaluation of large-scale changes in the DNA that can cause heritable disorders of any kind including those predisposing to malignancies, infertility and repeated pregnancy losses. Changes that are detected by OGM are the following:"
		listItems={[
			"Intra- and inter- chromosomal translocations both balanced and unbalanced",
			"Inversions",
			"Complex chromosomal rearrangements",
			"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD)",
			"Tandem repeat expansions (Fragile X, Myotonic Dystrophy 1 and 2, C9ORF72, and others)"
		]}
		/>
	</>


const ogm_cc_description = 
	<>
		<SampleTypesSection>
			All cells in the patient's body carry the same DNA variant(s) detected in this test. However,
			OGM requires extraction of ultrahigh molecular weight DNA and because of this requirement testing can
			be performed on either fresh blood, cultured cells or tissue biopsy. Saliva, buccal swab or formalin fixed
			samples cannot be used for OGM.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Our laboratory is one of a few laboratories performing OGM as a clinical 
			test in the world and the only one offering it for carrier testing. The method is based on barcoding
			high molecular weight individual DNA molecules, 200,000 base pair-long or longer. Each DNA fragment 
			has a unique barcode pattern depending on its sequence, that partially overlaps with the pattern of other
			fragments. From these partially overlapping long DNA fragments, an entire barcoded genome can be assembled. 
			Comparison of this assembly with a known reference barcode pattern calculated based on the reference sequence
			can detect increased distances between bars or altered order of bars, that correspond to altered genomic 
			architecture. These types of changes can have major functional consequences and thus disease causing 
			potential and can be identified reliably with Optical Genome Mapping only.
		</AdvantageSection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. 
			Couples receive a single report that focuses on variants that would severely affect their child. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection>
			The barcode is established by attachment of fluorescent molecules to DNA in
			a sequence specific manner. The bars are spaced on average about 1,000bp apart from each other, but
			the exact distance varies depending on the region. For this reason, the resolution of this method is about
			1,000 bp in most areas, but there are some areas where it is higher or lower. The method is best
			performed in conjunction with whole genome sequencing which allows precise identification of
			breakpoints associated with structural variants.
		</LimitationsSection>
	</>

const basicExomeIntro = 
	<>
		<PurposeSection>
			This test aims to establish the molecular cause of genetic disorders that people
			could transfer to their children. The results can be used to guide therapy and prognostication of disease
			outcomes. This test is for people who already show symptoms of a disorder. We offer carrier testing for
			people who worry about having variants that would cause them develop disease later in life. The reason
			for this distinction is that diagnostic and carrier testing data requires different types of analysis and
			reporting.
		</PurposeSection>
	</>

const basicExomeDescription =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient’s body carry the
			same disease causing DNA variant(s). This is the reason that these disorders can be diagnosed from
			different sample types such as blood, saliva, buccal swab, tissue biopsies from any organ or cultured
			cells. However, the best source is fresh blood since that can also be used for optical genome mapping in
			case additional testing is required.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Our Basic Exome test is based on Whole Genome Sequencing (SRG).
			That means that we obtain sequence for the entire genome of the patient which minimizes the number of
			false positive and false negative results seen in capture-based panel and exome studies and allows us to
			report out all known pathogenic variants irrespective of their location. Basic Exome is defined as coding
			regions +/- 100bps plus mitochondrial genome (SNV, deletions) and single exon resolution CNV calling
			over coding regions.
		</AdvantageSection>
		<br></br>
		<FinancialSection>
			This test can be upgraded to Expanded Exome (SRG505) or Whole Genome
			Sequencing (SRG506) and Optical Genome Mapping (OGM001-004) and combined tests (PRX001-004)
			without providing additional patient sample. Upon upgrade only the difference in the price of the tests has
			to be paid. This arrangement lightens the burden of the patient and yet accelerates the time to diagnosis.
		</FinancialSection>
		<br></br>
		<EasyReportsSection>
			All information is filtered and interpreted by a board certified surgical and molecular
			genetic pathologist. An easy-to-understand, concise report is issued with a clear diagnosis, allowing
			prognostication and choosing the best possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection>
			The method is limited to coding regions, exons, that are of particular interest
			to the patient and physician. The cause of the disease might be outside these regions. Repeat
			expansions, genomic losses, gains and rearrangements that do not result in loss of coding regions such
			as inversions, translocations cannot be detected. Duplications, while detectable, cannot be classified as
			tandem or inverted, and the genomic location for the duplicated fragment cannot be established. 
		</LimitationsSection>
	</>

const exomeReportingDescription =
	<>
	<p>For this test we obtain sequence from high molecular weight DNA for the entire genome of the patient but only report out regions of particular interest to the patient. This is accomplished without the use of any targeted enrichment of specific areas which minimizes the number of false positive and false negative results seen in capture-based panel and exomes studies. Using the Dragen alignment and variant calling pipeline, we can identify:</p>
	<br/>
	<ol className="padding-left-2x">
		<li>Single Nucleotide Variants (SNVs) and small insertions and deletions (InDels) in the nuclear and mitochondrial genome</li>
		<li>Known pathogenic variants are reported irrespective of their localization in the genome</li>
		<li>High resolution copy number changes (CNVs) within 100s of base pairs depending on the specific region</li>
		<li>Repeat expansion disorder screening</li>

	</ol>
	<br/>
	<p className={"has-text-weight-bold"}> Limitations of the method:</p>
	<ol className="padding-left-2x">
		<li> The method is limited to regions that are of particular interest to the patient and the ordering physician. The cause of the disease might be outside these regions.  For this reason, in case of a negative report we allow for upgrade to genome testing for a fee, but without the need for additional sample submission. </li>
		<li> Genomic rearrangements that do not result in loss of genetic material cannot be identified using short read technology alone. Such changes include inversions, when a fragment of DNA is broken out and reannealed in the opposite direction, and translocations, when a piece of DNA is transferred from its normal location on the chromosome to another location. Duplications, while detectable, cannot be classified as tandem or inverted, and the genomic location for the duplicated fragment cannot be established with certainty. The best approach to correct this deficiency is to use Bionano optical genome mapping in conjunction with Illumina short read whole genome sequencing and transcriptome data. For this reason we allow upgrade to OGM testing without providing additional material. </li>
		<li> The method does not provide precise sizing of repeats; sizing has to be done with alternative methodology. </li>
		<li> Longer repeats (1000s of base pairs) also cannot be sized accurately using this technology. One important example is the characterization of the FSHD locus. This deficiency again can be remedied by using this method in conjunction with Bionano optical genome mapping. The test can be updated with Bionano testing for a fee, but without the need for additional sample submission. </li>
	</ol>
	</>

// Methylome Read WGS
const aneuploidy_cd_intro = 
	<>
		<PurposeSection>
			This test aims to confirm the results of NIPT testing based on WGS information.
			 It is limited to reporting out CNVs greater than 10kb in length. 
			 The test is performed in both pre- and post-natal samples. 
			 The results can be used to guide reproductive decisions.
		</PurposeSection>
	</>

const aneuploidy_cd_description =
	<>
		<AdvantageSection>
			Unlike most other laboratories that use microarray to confirm NIPT results, we do this test using WGS for a similar cost.
			 This allows upgrades to Exome, Expanded Exome, and Whole Genome Sequencing from the same dataset with the additional cost only being the difference between the respective tests. 
			These include:
		</AdvantageSection>
		<TextWithList
			listItems = {[
				"High resolution copy number changes (CNVs)"
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies. 
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. An easy-to-understand, concise report is issued with a clear diagnosis, allowing prognostication and choosing the
			best possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList
			listItems={[
				"Only detects CNV greater than 10Kbs"
			]}
		/>
	</>

// Methylome Read WGS
const methylome_wgs_cd_intro = 
	<>
		<PurposeSection>
			This test aims to establish the molecular cause of imprinting genetic disorders, such as Prader-Willi/Angelman Syndromes, Beckwith-Wiedemann/Silver-Russell Syndromes. Also this test allows determination of biased X chromosome inactivation. The results can be used to guide therapy and prognostication of disease
			outcomes. This test is for people who already show symptoms of a disorder. 
		</PurposeSection>
	</>

const methylome_wgs_cd_description =
	<>
		<AdvantageSection>
			Unlike most other laboratories that only report out DNA sequence changes, this test allows Praxis Genomics to also assess the methylation status and thus expression of genes across the genome.
			The test is performed using Oxford Nanopore technologies (ONT) and relies on direct identification of modified residues without the need for chemical (bisulfite) treatment. 
			Long read sequencing can identify all types of variants not easily detectable by short read technology. These include:
		</AdvantageSection>
		<TextWithList
			listItems = {[
				"Detection of imprinting disorders: Prader-Willi/Angelman, Beckwith-Wiedemann/Silver-Russell, X-chromosome inactivation bias, and others",
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Repeat expansion detecting and sizing",
				"Uniparental disomy",
				"Variant calling in genes with multiple pseudogenes eg. PKD1, SMN1/2, etc.",
				<>
					{<a href='https://medlineplus.gov/ency/article/000564.htm' target="_blank" rel="noopener noreferrer">Gaucher disease</a>} (GBA) diagnosis
				</>
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies. 
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. An easy-to-understand, concise report is issued with a clear diagnosis, allowing prognostication and choosing the
			best possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList
			listItems={[
				"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) cannot be detected",
				"Robertsonian translocations cannot be detected with this method",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with long read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

// Long Read WGS
const lr_wgs_cd_intro = 
	<>
		<PurposeSection>
			This test aims to establish the molecular cause of genetic disorders that people
			could transfer to their children. The results can be used to guide therapy and prognostication of disease
			outcomes. This test is for people who already show symptoms of a disorder. We offer carrier testing for
			people who worry about having variants that would cause them develop disease later in life. The reason
			for this distinction is that diagnostic and carrier testing data requires different types of analysis and
			reporting.
		</PurposeSection>
		<b><i className='is-size-7 has-text-danger'>* for research purposes only</i></b>
	</>

const lr_wgs_ci_intro = 
	<>
		<PurposeSection>
			This test aims to detect dominant genetic disorders in healthy individuals that
			can predispose to malignancies, neurodegenerative disorders, repeated pregnancy losses and recessive conditions
			that might manifest in their children. The results can be used to guide reproductive decisions,
			prevention of the manifestation of disorders or preparations to deal with them.
		</PurposeSection>
	</>

const lr_wgs_cc_intro = 
	<>
		<PurposeSection>
			This test aims to detect recessive conditions in a couple that might manifest 
			in their children or to establish the cause of repeated pregnancy losses. 
			The results can be used to guide reproductive decisions, prevention of the manifestation 
			of disorders or preparations to deal with them. 
		</PurposeSection>
	</>

const lr_wgs_cc_description =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's 
			body carry the same disease causing DNA variant(s). This is the reason that 
			these disorders can be diagnosed from different sample types such as blood, saliva, 
			buccal swab, tissue biopsies from any organ or cultured cells. 
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Unlike most other laboratories that only report out small variants, we identify all types of variants detectable
			by short read technology. These include:
		</AdvantageSection>
		<TextWithList
			listItems = {[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs) ",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels) ",
				"High resolution copy number changes (CNVs) ",
				"Structural variants such as inversions and translocations ",
				"Changes in mitochondrial genome level ",
				"Changes in mitochondrial genome content ",
				"Uniparental disomy ",
				"Tandem repeat expansions (Fragile X, Myotonic Dystrophy 1 and 2, C9ORF72, and others) ",
				"SMA carrier status ",
				<>
					{<a href='https://medlineplus.gov/ency/article/000564.htm' target="_blank" rel="noopener noreferrer">Gaucher disease</a>} (GBA) diagnosis
				</>
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies.
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. 
			Couples receive a single report that focuses on variants that would severely affect their child. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList
			listItems={[
				"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) cannot be detected",
				"Large tandem repeat expansions (Fragile X, Myotonic Dystrophy 1, 2, C9ORF72, etc.) cannot be sized",
				"Variants can be called only in uniquely mappable regions",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with short read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

const lr_wgs_cd_description =
	<>
		<AdvantageSection>
			Unlike most other laboratories that only report out small variants,
			 long read sequencing can identify all types of variants not easily detectable by short read technology. These include:
		</AdvantageSection>
		<TextWithList
			listItems = {[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Repeat expansion detecting and sizing",
				"Uniparental disomy",
				"Variant calling in genes with multiple pseudogenes eg. PKD1, SMN1/2, etc.",
				<>
					{<a href='https://medlineplus.gov/ency/article/000564.htm' target="_blank" rel="noopener noreferrer">Gaucher disease</a>} (GBA) diagnosis
				</>
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies. 
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. An easy-to-understand, concise report is issued with a clear diagnosis, allowing prognostication and choosing the
			best possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList
			listItems={[
				"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) cannot be detected",
				"Robertsonian translocations cannot be detected with this method",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with long read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

const lr_wgs_ci_description =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body carry the same disease 
			causing DNA variant(s). This is the reason that these disorders can be diagnosed from different sample types such
			as blood, saliva, buccal swab, tissue biopsies from any organ or cultured cells.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Unlike most other laboratories that only report out small variants, we identify all types of variants detectable
			by short read technology. These include:
		</AdvantageSection>
		<TextWithList
			listItems = {[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Uniparental disomy",
				"Repeat expansions (Fragile X syndrome)",
				"SMA carrier status",
				<>
					{<a href='https://medlineplus.gov/ency/article/000564.htm' target="_blank" rel="noopener noreferrer">Gaucher disease</a>} (GBA) diagnosis
				</>
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies.
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. 
			An easy to understand, concise report is issued with a clear diagnosis, allowing prognostication and choosing the
			best possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList
			listItems={[
				"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) cannot be detected",
				"Large tandem repeat expansions (Fragile X, Myotonic Dystrophy 1, 2, C9ORF72, etc.) cannot be sized",
				"Variants can be called only in uniquely mappable regions",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with short read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

// WGS ; PLA 0265U
const wgs_cd_intro = 
	<>
		<PurposeSection>
			This test aims to establish the molecular cause of genetic disorders that people
			could transfer to their children. The results can be used to guide therapy and prognostication of disease
			outcomes. This test is for people who already show symptoms of a disorder. We offer carrier testing for
			people who worry about having variants that would cause them develop disease later in life. The reason
			for this distinction is that diagnostic and carrier testing data requires different types of analysis and
			reporting.
		</PurposeSection>
	</>

const wgs_cd_description =
	<>
		<AdvantageSection>
			Unlike most other laboratories that only report out small variants, we identify all types of variants detectable by short read technology. These include:
		</AdvantageSection>
		<TextWithList
			listItems = {[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Repeat expansions",
				"Uniparental disomy",
				"Repeat expansions (Fragile X syndrome)",
				"SMA diagnosis",
				<>
					{<a href='https://medlineplus.gov/ency/article/000564.htm' target="_blank" rel="noopener noreferrer">Gaucher disease</a>} (GBA) diagnosis
				</>
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies. 
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. An easy-to-understand, concise report is issued with a clear diagnosis, allowing prognostication and choosing the
			best possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList
			listItems={[
				"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) cannot be detected",
				"Large tandem repeat expansions (Fragile X, Myotonic Dystrophy 1, 2, C9ORF72, etc.) cannot be sized",
				"Variants can be called only in uniquely mappable regions",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with short read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

const wgs_ci_intro = 
	<>
		<PurposeSection>
			This test aims to detect dominant genetic disorders in healthy individuals that
			can predispose to malignancies, neurodegenerative disorders, repeated pregnancy losses and recessive conditions
			that might manifest in their children. The results can be used to guide reproductive decisions,
			prevention of the manifestation of disorders or preparations to deal with them.
		</PurposeSection>
	</>

const wgs_ci_description =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body carry the same disease 
			causing DNA variant(s). This is the reason that these disorders can be diagnosed from different sample types such
			as blood, saliva, buccal swab, tissue biopsies from any organ or cultured cells.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Unlike most other laboratories that only report out small variants, we identify all types of variants detectable
			by short read technology. These include:
		</AdvantageSection>
		<TextWithList
			listItems = {[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Uniparental disomy",
				"Repeat expansions (Fragile X syndrome)",
				"SMA carrier status",
				<>
					{<a href='https://medlineplus.gov/ency/article/000564.htm' target="_blank" rel="noopener noreferrer">Gaucher disease</a>} (GBA) diagnosis
				</>
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies.
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. 
			An easy to understand, concise report is issued with a clear diagnosis, allowing prognostication and choosing the
			best possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList
			listItems={[
				"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) cannot be detected",
				"Large tandem repeat expansions (Fragile X, Myotonic Dystrophy 1, 2, C9ORF72, etc.) cannot be sized",
				"Variants can be called only in uniquely mappable regions",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with short read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

// WGS ; PLA 0265U
const wgs_cc_intro = 
	<>
		<PurposeSection>
			This test aims to detect recessive conditions in a couple that might manifest 
			in their children or to establish the cause of repeated pregnancy losses. 
			The results can be used to guide reproductive decisions, prevention of the manifestation 
			of disorders or preparations to deal with them. 
		</PurposeSection>
	</>

const wgs_cc_description =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's 
			body carry the same disease causing DNA variant(s). This is the reason that 
			these disorders can be diagnosed from different sample types such as blood, saliva, 
			buccal swab, tissue biopsies from any organ or cultured cells. 
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Unlike most other laboratories that only report out small variants, we identify all types of variants detectable
			by short read technology. These include:
		</AdvantageSection>
		<TextWithList
			listItems = {[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs) ",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels) ",
				"High resolution copy number changes (CNVs) ",
				"Structural variants such as inversions and translocations ",
				"Changes in mitochondrial genome level ",
				"Changes in mitochondrial genome content ",
				"Uniparental disomy ",
				"Tandem repeat expansions (Fragile X, Myotonic Dystrophy 1 and 2, C9ORF72, and others) ",
				"SMA carrier status ",
				<>
					{<a href='https://medlineplus.gov/ency/article/000564.htm' target="_blank" rel="noopener noreferrer">Gaucher disease</a>} (GBA) diagnosis
				</>
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies.
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. 
			Couples receive a single report that focuses on variants that would severely affect their child. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList
			listItems={[
				"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) cannot be detected",
				"Large tandem repeat expansions (Fragile X, Myotonic Dystrophy 1, 2, C9ORF72, etc.) cannot be sized",
				"Variants can be called only in uniquely mappable regions",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with short read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

// Ext. WGS ; PLA 0265U
const extWholeGenomeDataAnalysisIntro = 
	<>
		<PurposeSection>
			 We reanalyze the sequence data obtained at another lab from the entire genome of
			the patient to establish the molecular cause of genetic disorders that people could transfer to their
			children. Reevaluating their data using the Dragen alignment and our custom proprietary variant calling
			pipeline can identify disease causing variants that escaped their attention. This test can be offered both
			for diagnosis or carrier testing. The results can be used to guide therapy and prognostication of disease
			outcomes or guide reproductive decisions. <span className='has-text-weight-bold'>Sample types:</span> genomic FASTQ or BAM files
		</PurposeSection>
	</>

const extWholeGenomeDataAnalysis =
	<>
		<AdvantageSection>
			Unlike most other laboratories that only report out small variants, we
			identify all types of variants detectable by short read technology. These include:
		</AdvantageSection>
		<br></br>
		<TextWithList 
			listItems={[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Repeat expansions",
				"Uniparental disomy",
				"Repeat expansions (Fragile X syndrome)",
				"SMA diagnosis",
				<>
					{<a href='https://medlineplus.gov/ency/article/000564.htm' target="_blank" rel="noopener noreferrer">Gaucher disease</a>} (GBA) diagnosis
				</>
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies.
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			Easy to read reports: All this information is filtered and interpreted by a 
			medical doctor. An easy-to-understand, concise report is issued with a clear diagnosis, allowing prognostication and choosing the best possible therapy for the patient.
		</EasyReportsSection>
		<br></br>
		<LimitationsSection></LimitationsSection>
		<TextWithList
			listItems={[
				"Contraction of a subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) cannot be detected",
				"Large tandem repeat expansions (Fragile X, Myotonic Dystrophy 1, 2, C9ORF72, etc.) cannot be sized",
				"Variants can be called only in uniquely mappable regions",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with short read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

const extWholeGenomeDataAnalysisSampleSpec =
	<>
		<p>All datasets submitted should be labeled with the patient's name, date of birth, and MRN / ID number. Data deliveries or transfers are accepted Monday-Friday. Data integrity will be assessed upon receipt and any issues will be noted and communicated back to the provider. </p>
		<div className="padding-top">
			<h5 className="has-text-weight-bold">Contact laboratory to determine best method to transfer fastq, bam of vcf format data based on data availability and data storage location.</h5>
		</div>
	</>

// Combination Testing ; PLA 0267U
const combinationIntro = 
	<>
		<PurposeSection>
			This test combines Optical Genome Mapping (OGM) and Whole Genome Sequencing
			(SRG) with potential addition of transcriptome (SRT) sequencing. It aims to establish the molecular cause
			of genetic disorders that people could transfer to their children. The results can be used to guide therapy
			and prognostication of disease outcomes. This test is for people who already show symptoms of a
			disorder. We offer carrier testing for people who worry about having variants that would cause them
			develop disease later in life. The reason for this distinction is that diagnostic and carrier testing data
			requires different types of analysis and reporting.
		</PurposeSection>
	</>

const combinationDescription =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body carry the
			same disease causing DNA variant(s). This is the reason that these disorders can be diagnosed from
			different sample types such as blood, saliva, buccal swab, tissue biopsies from any organ or cultured
			cells. However, for the OGM component of the test only fresh blood or tissue is acceptable.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Currently only Praxis Genomics offers co-analysis and co-interpretation
			of OGM, SRG and SRT data. These datasets correspond to a high level overview, high resolution
			assessment, and functional assessment of the human genome. The complementary strength of these
			methodologies provides us with unparalleled diagnostic sensitivity and accuracy based on detection and
			reporting of the following types of variants:
		</AdvantageSection>
		<TextWithList 
			listItems={[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"SMA diagnosis",
				"Gaucher disease (GBA) diagnosis",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Uniparental disomy",
				"Repeat expansions (Fragile X, Myotonic Dystrophy 1 and 2, C9ORF72, and others) detection and sizing",
				"Contraction of subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD)",
				"Full characterization of genomic rearrangements"
			]}
		/>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. An easy-to-understand, concise report is issued with a clear diagnosis,
			allowing prognostication and choosing the best possible therapy for the patient. 
		</EasyReportsSection>
	</>

// Combination Carrier Couple Testing ; PLA 0267U
const comb_ci_intro = 
	<>
		<PurposeSection>
			This test aims to detect dominant genetic disorders in healthy individuals that can 
			predispose to malignancies, neurodegenerative disorders, repeated pregnancy losses and 
			recessive conditions that might manifest in their children. The results can be used to 
			guide reproductive decisions, prevention of the manifestation of disorders or preparations to deal with them.
		</PurposeSection>
	</>

const comb_ci_description =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body carry the
			same disease causing DNA variant(s). This is the reason that these disorders can be diagnosed from
			different sample types such as blood, saliva, buccal swab, tissue biopsies from any organ or cultured
			cells. However, for the OGM component of the test only fresh blood or tissue is acceptable.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Currently only Praxis Genomics offers co-analysis and co-interpretation of Optical Genome Mapping (OGM), 
			Whole Genome Sequencing (SRG) and Transcriptome (SRT) data. These datasets correspond to a high level overview, 
			a high resolution assessment, and functional assessment of the human genome. The complementary strength of 
			these methodologies provides us with unparalleled diagnostic sensitivity and accuracy based on detection and 
			reporting of the following types of variants:
		</AdvantageSection>
		<TextWithList 
			listItems={[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Uniparental disomy",
				"Repeat expansions (Fragile X, Myotonic Dystrophy 1 and 2, C9ORF72, and others) detection and sizing",
				"SMA diagnosis",
				"Gaucher disease (GBA) diagnosis",
				"Contraction of subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD)",
				"Full characterization of genomic rearrangements"
			]}
		/>
		<p> 
			This approach detects those undescribed and uncharacterized variants not 
			currently found in targeted panels and is especially useful in determining 
			the cause of infertility and repeated pregnancy losses.
		</p>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. 
			For individuals we report on conditions that would severely affect their or their children's quality 
			of life and conditions of concern irrespective of their severity.
		</EasyReportsSection>
	</>

// Combination Carrier Ind Testing ; PLA 0267U
const comb_cc_intro = 
	<>
		<PurposeSection>
			This test aims to detect dominant genetic disorders in healthy individuals that can 
			predispose to malignancies, neurodegenerative disorders, repeated pregnancy losses and 
			recessive conditions that might manifest in their children. The results can be used to 
			guide reproductive decisions, prevention of the manifestation of disorders or preparations to deal with them.
		</PurposeSection>
	</>

const comb_cc_description =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient�s body carry the same disease 
			causing DNA variant(s). This is the reason that these disorders can be diagnosed from different sample 
			types such as blood, saliva, buccal swab, tissue biopsies from any organ or cultured cells. However, 
			for the OGM component of the test only fresh blood or tissue is acceptable.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Currently only Praxis Genomics offers co-analysis and co-interpretation of Optical Genome Mapping (OGM), 
			Whole Genome Sequencing (SRG) and Transcriptome (SRT) data. These datasets correspond to a high level overview, 
			a high resolution assessment, and functional assessment of the human genome. The complementary strength of 
			these methodologies provides us with unparalleled diagnostic sensitivity and accuracy based on detection 
			and reporting of the following types of variants: 
		</AdvantageSection>
		<TextWithList 
			listItems={[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs) ",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels) ",
				"SMA diagnosis ",
				"Gaucher disease (GBA) diagnosis ",
				"High resolution copy number changes (CNVs) ",
				"Structural variants such as inversions and translocations ",
				"Changes in mitochondrial genome level ",
				"Changes in mitochondrial genome content ",
				"Uniparental disomy ",
				"Repeat expansions (Fragile X, Myotonic Dystrophy 1 and 2, C9ORF72, and others) detection and sizing ",
				"Contraction of subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD) ",
				"Full characterization of genomic rearrangements "
			]}
		/>
		<p> 
			This approach detects those undescribed and uncharacterized variants not currently 
			found in targeted panels and is especially useful in determining the cause of 
			infertility and repeated pregnancy losses.
		</p>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a medical doctor. 
			Couples receive a single report that focuses on variants that would severely affect their child. 
		</EasyReportsSection>
	</>

// Combination Testing ; PLA 0300U
const somaticCombinationIntro = 
	<>
		<PurposeSection>
			This test aims to establish the molecular cause of malignancies and can provide
			diagnostic, prognostic and management information for optimized personalized treatment for the patient.
		</PurposeSection>
	</>

const somaticCombinationDescription =
	<>
		<SampleTypesSection>
			Since in malignancies the genetic abnormalities are only present in a subset of the
			patient's cells, the analysis is performed by comparison of the genetic content of the patient's normal and
			abnormal cells. Acceptable normal samples are blood, buccal swab, saliva, or tissue biopsies from
			unaffected areas. The abnormal cells need to derive from the affected organ or region of the body. Both
			fresh blood, frozen tissue samples and formalin fixed (FFPE) samples can be analyzed with this method.
			Whole genome amplification allows diagnosis from low cellularity biopsy samples as well. However, for
			the OGM component of the test only fresh blood or tissue is acceptable.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Currently only Praxis Genomics offers co-analysis and co-interpretation
			of Optical Genome Mapping, Whole Genome Sequencing (WGS) and Transcriptome data. These datasets
			correspond to a high resolution assessment, a high level overview, and functional assessment of the
			human genome. 
		</AdvantageSection>
		<br></br>
		<TextWithList 
			text = "The complementary strength of these methodologies provides us with unparalleled diagnostic sensitivity and accuracy based on detection and reporting of the following types of variants:"
			listItems={[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Repeat expansions",
				"Uniparental disomy",
				"Repeat expansions detection and sizing",
				"Contraction of subtelomeric macrosatellite repeats (e.g. D4Z4 FSHD)",
				"Full characterization of genomic rearrangements",
				"LOH Score, TAI Score, LST Score, HRD Score",
				"TMB",
				"MSI"
			]}
		/>
		<EasyReportsSection>
			All this information is filtered and interpreted by a board certified surgical and
			molecular genetic pathologist. An easy-to-understand, concise report is issued with a clear diagnosis,
			allowing prognostication and choosing the best possible therapy for the patient. 
		</EasyReportsSection>
	</>

// Somatic OGM ; PLA 0299U
const somaticOGMIntro = 
	<>
		<PurposeSectionWithList
			text = "OGM is for the evaluation of large-scale changes in the DNA that commonly underlie the pathogenesis of malignancies and can provide diagnostic, prognostic and management information for optimized personalized treatment for the patient. Changes that are detected by OGM are the following:"
			listItems = {[
				"Intra- and inter-chromosomal translocations both balanced and unbalanced.",
				"Inversions",
				"Complex chromosomal rearrangements"
			]}
		/>
	</>

const somaticOGMDescription =
	<>
		<SampleTypesSection>
			Since in malignancies the genetic abnormalities are only present in a subset of the
			patient's cells, the analysis is performed by comparison of the genetic content of the patient's normal and
			abnormal cells. Acceptable normal samples are blood, buccal swab, saliva, or tissue biopsies from
			unaffected areas. The abnormal cells need to derive from the affected organ or region of the body and
			need to be blood or frozen tissue samples since formalin fixed samples cannot be analyzed with this
			method.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Praxis Genomics is one of a handful of laboratories that provide OGM
			analysis of malignancies, and the only one that offer a comparative normal tumor test. Although specific
			conditions are often associated with specific mutations allowing targeted testing, in many cases the
			cause of the malignancy is unique to the individual or is due to many mutations necessitating genomewide testing for all types of variants. The goal is to select the most appropriate therapeutic approach for
			the patient's condition to prevent relapse following treatment. If relapse occurs, testing is performed to
			identify alternative therapeutic approaches.
		</AdvantageSection>
		<br></br>
		<MethodologySection>
			OGM is based on barcoding high molecular weight individual DNA molecules, 200000 base
			pair-long or longer. Each DNA fragment has a unique barcode pattern depending on its sequence that
			partially overlaps with the pattern of other fragments derived from adjacent areas. From these partially
			overlapping long DNA fragments, an entire barcoded genome can be assembled. Comparison of this
			assembly with a known reference barcode can detect alterations of the barcode pattern, such as
			increased distances between bars or altered order of bars, that correspond to altered genomic
			architecture. These types of changes can have major functional consequences and thus disease causing
			potential and can be identified reliably with Optical Genome Mapping only.
		</MethodologySection>
		<br></br>
		<LimitationsSection>
			The barcode is established by attachment of fluorescent molecules to DNA in a sequence specific
			manner. The bars are spaced on average about 1,000bp apart from each other, but the exact distance
			varies depending on the region. For this reason, the resolution of this method is about 1,000 bp in most
			areas, but there are some areas where it is higher or lower. The method is best performed in conjunction
			with whole genome sequencing which allows precise identification of breakpoints associated with
			structural variants
		</LimitationsSection>
	</>

// Trascriptome ; PLA 0266U
const transcriptomeIntro = 
	<>
		<PurposeSection>
			Transcriptome analysis is a method that allows evaluation of the functional
			consequences of DNA variants discovered by optical genome mapping or DNA sequencing. These are:
		</PurposeSection>
		<ListWithSubList 
			listItems = {[
				"Deletions, insertions, inversions, translocations affecting",
				[
					"regulatory regions resulting in altered gene expression or alternative transcription initiation and termination sites",
					"coding regions resulting in shortened or expanded or chimeric transcripts",
					"imprinted loci resulting in some cases complete loss of transcription (Prader Willi sy.)"
				],
				"Single nucleotide changes or small insertions deletions affecting",
				[
					"splicing resulting in exon skipping or alternative splicing",
					"translation initiation and termination resulting in truncated or absent transcripts"
				],
				"Repeat number changes affecting",
				["chromatin structure and adjacent gene expression (FSHD)"],
				"Repeat expansions affecting transcript levels & splicing."

			]}
		/>
	</>

const transcriptomeDescription =
	<>
		<SampleTypesSection>
			With rare exceptions, in constitutional disorders all cells in the patient's body carry the
			same disease causing DNA variant(s). This is the reason that genetic disorders can be diagnosed from
			different sample types such as blood, saliva, buccal swab, tissue biopsies from any organ or cultured
			cells. However, for the transcriptome analysis the tissue type has to be carefully evaluated since not all
			genes are expressed in all tissues. With that caveat, both fresh and formalin fixed (FFPE) samples can be
			used for transcriptome analysis.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Only Praxis Genomics offers clinical transcriptome testing in the world.
			We consider it an essential tool for functional assessment of previously unclassified or undescribed
			variants detected by Whole Genome Sequencing or Optical Genome Mapping. Indeed, transcriptome
			analysis provides us an integrated readout of the changes in DNA and allows us to interpret the
			significance of such changes based on their functional effects.
		</AdvantageSection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a board certified surgical and
			molecular genetic pathologist. An easy-to-understand, concise report is issued with a clear diagnosis,
			allowing prognostication and choosing the best possible therapy for the patient.
		</EasyReportsSection>
		<br></br>
		<LimitationsSection>
			Transcriptome studies are sensitive to the type of cell or tissue they are
			performed on, and they are most effective if used in a targeted manner in conjunction with the whole
			genome sequencing and optical genome mapping data of the patient.
		</LimitationsSection>
	</>

//  Somatic Transcriptome ; PLA 0298U
const somaticTranscriptomeIntro = 
	<>
		<PurposeSection>
			Transcriptome analysis is a method that allows evaluation of the functional
			consequences of DNA variants discovered by optical genome mapping or DNA sequencing. Our test
			allows comparative analysis of the normal and malignant cell transcriptomes, thus allowing identification
			of the following changes driving the cellular transformation: 
		</PurposeSection>
		<ListWithSubList 
			listItems = {[

				"Deletions, insertions, inversions, translocations affecting",
				[
					"regulatory regions resulting in altered gene expression or alternative transcription initiation and termination sites",
					"coding regions resulting in shortened or expanded or chimeric transcripts",
					"imprinted loci resulting in some cases to complete loss of transcription"
				],
				"Single nucleotide changes or small insertions deletions affecting",
				[
					"splicing resulting in exon skipping or alternative splicing",
					"translation initiation and termination resulting in truncated or absent transcripts"
				],
				"Repeat number changes affecting chromatin structure and adjacent gene expression.",
				"Repeat expansions affecting transcript levels & splicing"
			]}
		/>
		<br></br>
	</>

const somaticTranscriptomeDescription =
	<>
		<SampleTypesSection>
			For comparative transcriptome analysis the normal and tumor tissue of the affected organ
			should be provided. Both fresh and formalin fixed (FFPE) samples can be used for transcriptome
			analysis.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Only Praxis Genomics offers clinical transcriptome testing in the world.
			We consider it an essential tool for evaluation of malignancies, since transcriptome analysis provides us
			an integrated readout of the changes in DNA and allows us to interpret the significance of such changes
			based on their functional effects. Transcriptome analysis provides clues to the pathogenesis of
			malignancies and can provide diagnostic, prognostic and management information for optimized
			personalized treatment for the patient.
		</AdvantageSection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a board certified surgical and
			molecular genetic pathologist. An easy-to-understand, concise report is issued with a clear diagnosis,
			allowing prognostication and choosing the best possible therapy for the patient.
		</EasyReportsSection>
		<br></br>
		<LimitationsSection>
			The method is based on the fact that changes in DNA only cause a disease
			phenotype if they are expressed. This means that only mutations that are transcribed into RNA will have
			an effect. This test is a comparative test: we preferably compare the transcriptome of the tumor to the
			transcriptome of the tissue of origin. In some cases, finding the tissue of origin might be difficult or
			impossible, thus in some malignancies transcriptome has to be analyzed in comparison with other tumor
			samples. Transcriptome studies are sensitive to the type of cell or tissue they are performed on, and they
			are most effective if used in a targeted manner in conjunction with the whole genome sequencing and
			optical genome mapping data of the patient.
		</LimitationsSection>
	</>

// Somatic WGS ; PLA 0297U
const somaticWholeGenomeIntro = 
	<>
		<PurposeSection>
			This test aims to establish the molecular cause of malignancies and can provide
			diagnostic, prognostic and management information for optimized personalized treatment for the patient.
		</PurposeSection>
	</>

const somaticWholeGenomeDescription =
	<>
		<SampleTypesSection>
			Since in malignancies the genetic abnormalities are only present in a subset of the
			patient's cells, the analysis is performed by comparison of the genetic content of the patient's normal and
			abnormal cells. Acceptable normal samples are blood, buccal swab, saliva, or tissue biopsies from
			unaffected areas. The abnormal cells need to derive from the affected organ or region of the body. Both
			fresh blood, frozen tissue samples and formalin fixed (FFPE) samples can be analyzed with this method.
			Whole genome amplification allows diagnosis from low cellularity biopsy samples as well.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Unlike most other laboratories that only report out small variants, we
			identify all types of variants detectable by short read technology.
		</AdvantageSection>
		<br></br>
		<TextWithList 
			text = "These include:"
			listItems = {[
				"Single Nucleotide Variants in the nuclear and mitochondrial genome (SNVs)",
				"Small insertions and deletions in the nuclear and mitochondrial genome (InDels)",
				"High resolution copy number changes (CNVs)",
				"Structural variants such as inversions and translocations",
				"Changes in mitochondrial genome level",
				"Changes in mitochondrial genome content",
				"Repeat expansions",
				"Uniparental disomy",
				"LOH Score, TAI Score, LST Score, HRD Score",
				"TMB",
				"MSI"
			]}
		/>
		<TechnologySection>
			This technique is used to obtain sequence from the entire genome of
			the patient which consists of about 3 billion units. For understanding the scope of this study, remember
			that the average human life is about 3 billion seconds long. This is accomplished without the use of any
			targeted enrichment of specific areas which eliminates the number of false positive and false negative
			results seen in panel and exome studies.
		</TechnologySection>
		<br></br>
		<EasyReportsSection>
			All this information is filtered and interpreted by a board certified surgical and
			molecular genetic pathologist. An easy-to-understand, concise report is issued with a clear diagnosis,
			allowing prognostication and choosing the best possible therapy for the patient. 
		</EasyReportsSection>
		<br></br>
		<LimitationsSection />
		<TextWithList 
			listItems = {[
				"Variants can be called only in uniquely mappable regions",
				"Full characterization of genomic rearrangements may require optical genome mapping in conjunction with short read whole genome sequencing and transcriptome data."
			]}
		/>
	</>

// Heredity & Relatedness 
const heredityTestIntro =
	<>
		<PurposeSection>
			Heredity testing aims to establish the paternal and maternal origins of an individual
			based on analysis of the single nucleotide variants (SNVs) in the Y- chromosome and the mitochondrial
			genome, respectively. The mitochondrial genome and the Y chromosome are like molecular diaries. Every
			few generations, a variant is established and thus variants introduced over thousands of generations take
			us back to the molecular Adam and Eve. 
			<br></br>
			<br></br>
			Relatedness Testing aims to establish the relationships between
			two individuals. This type of testing relies on the variants that are shared between individuals on the
			autosomes (Chromosomes 1 - 22). It takes into account about 200 million well established relatively rare
			variants that will provide a reliable estimate of the degree of relatedness between two individuals up to
			five degrees of separation.
		</PurposeSection>
	</>

const heredityTestDescription = 
	<>
		<SampleTypesSection>
			The analysis can be performed from different sample types such as blood, saliva, buccal
			swab, tissue biopsies, but blood is the preferred sample.
		</SampleTypesSection>
		<br></br>
		<AdvantageSection>
			Commonly used heredity tests take a targeted approach in identifying
			phylogenetic origin. They analyze the sample for the presence of a set of variants that have been well
			described in many people and do not look for personal variants. If they identify variants that have not
			been previously seen, they do not report them until they find them in at least one other person being
			tested. We believe that identifying and reporting variants throughout the entire nonrepetitive section of
			the Y-chromosome and mitochondrial genome is important. This information can help you identify
			relatives that you share a common ancestor with. In addition, if both you and your relative are analyzed by
			us, it will allow determination of the time to the most recent common ancestor more precise.
		</AdvantageSection>
		<br></br>
		<EasyReportsSection>
			All this information is then condensed and provided to our customers in an concise,
			easy-to-understand report.
		</EasyReportsSection>
	</>

/////////////////////////////////////////////////// SAMPLE SPECIFICATIONS ///////////////////////////////////////////////////////////////////
export const genericSampleIntro =
	<>
		<p>
		  All specimen containers received should be labeled with the patient's name,
		  date of birth, and MRN / ID number. The collection date should be written
		  prominently on the tube. At least two identifiers should be listed on specimen
		  containers. Specimen deliveries are accepted Monday-Friday for all specimen types.
		  Specimen integrity will be assessed upon receipt and any discrepancies will be noted
		  and communicated back to the provider.
		  Sample containers must be shipped in <span className="has-text-weight-bold"><a href="https://www.fedex.com/content/dam/fedex/us-united-states/services/UN3373_fxcom.pdf">IATA UN3373 Compliant Packaging</a></span>. See the link below for Shipping Resources to learn more.
		</p>
		  <br></br>
		<div className='buttons'>
			<Link className='button is-link is-hoverable-button shadow-lift' to="/shipping" target="_blank" rel="noopener noreferrer"> 
				<h1 className=''>
					Shipping Resources
				</h1>
			</Link>
			<Link className='button is-link is-hoverable-button shadow-lift' to="/billing" target="_blank" rel="noopener noreferrer"> 
				<h1>
					Billing Information
				</h1>
			</Link>
		</div>
		<br></br>
		<br></br>
		<b>Failure to ship specimens appropriately may result in delivery delays due to customs inspections. Samples overly delayed will likely be rejected due to compromised sample integrity.</b>
	</>

export const aneuploidySpecDetail =
	<>
		<p>
			We accept amniotic fluid, chorionic villi, products of conception, and peripheral blood samples for this test. 
			Please consult with support@praxisgenomics.com before sending samples to discuss shipping and turnaround time. 
		</p>
		<br></br>
		{genericSampleIntro}
	</>


export const OGMSampleDetail =
	<>
		{genericSampleIntro}
	</>

export const transcriptomeSampleDetail =
	<>
		{genericSampleIntro}
	</>

export const additionalSpecs =
	<>
		<h1 className="has-text-weight-bold">Note: </h1>
		All datasets submitted should be labeled with the patient's name, date of birth, and MRN / ID number. Data deliveries or transfers are accepted Monday-Friday. Data integrity will be assessed upon receipt and any issues will be noted and communicated back to the provider. Please contact info@praxisgenomics.com for organizing a secure data transfer.
	</>

export const combinationSampleDetail =
	<>
		{genericSampleIntro}
	</>

export const genericSampleRequirements =
	<>
		{/* <ul className="sampleReqList is-size-6"> */}
		<div className="content">
			<ul className="is-size-6">
				<li>A minimum of 3mL is required for all blood samples.</li>
				<li>Frozen Blood and Tissue samples must be shipped on dry ice to ensure thawing does not occur. </li>
				<li>Please email support@praxisgenomics.com before shipping Cell Cultures or Frozen Tissue to confirm conditions and volumes. For transcriptome studies please contact us to discuss the most appropriate tissue to perform testing on.</li>
				<li>Standard turnaround time is four weeks. STAT testing is two weeks. Contact support@praxisgenomics.com for projects requiring shorter lead times.</li>
				<li>For clinical extracted DNA samples, Praxis Genomics only accepts nucleic acids for which extraction or isolation is performed in an appropriately CLIA-certified facility or one meeting equivalent requirements as determined by the CAP and/or CMS.</li>
			</ul>
		</div>
	</>

 /////////////////////////////////////////////////////////////SHIPPING//////////////////////////////////////////////////////////////////////////
 export const ShippingInformation =
	<div id="shippingdiv">
		<h1 className="shippingtitle" >Additional Sample Specification Details</h1>
		<p>Fresh blood drawn in EDTA tubes for OGM or PRX testing should be shipped using a 2-day courier service at room temperature within 1-2 days. A minimum of 3mL blood is required. Frozen blood is acceptable if it was frozen no later than four days after draw and shipped on dry ice.<br></br><br></br>
		Tempus RNA tubes are acceptable for Transcriptome testing and can be shipped at room temperature once stabilized.<br></br><br></br>
		Saliva and buccal swab collection kits should be stabilized for storage according to kit instructions and shipped at room temperature. These specimens are only suitable for WGS/KFM Testing due to their inability to stabilize RNA and high molecular weight gDNA.<br></br><br></br>
		Lymphoblast and fibroblast cultured cells are accepted for all testing types. These can be frozen and sent on dry ice in a freezing media, or at room temperature in two T25 (or larger) flasks filled with growth media and caps wrapped with paraffin. Please consult <a href="mailto:support@praxisgenomics.com">support@praxisgenomics.com</a> prior to sending or cell culture products to confirm appropriate shipping conditions and media.<br></br><br></br>
		Frozen Tissue can be accepted for most testing, but the source must be pre-approved. Please consult <a href="mailto:support@praxisgenomics.com">support@praxisgenomics.com</a> prior to sending frozen tissue specimens.</p><br></br>
		<h1 className="shippingtitle">Shipping Assistance for International Customers</h1>
		<p>Praxis Genomics recommends using a courier service like FedEx International Priority or an equivalent when shipping samples. All biological samples must be shipped in IATA UN3373 Compliant Packaging regardless of courier.<br></br><br></br>
		<a className="testlinks button is-hoverable-button is-link shadow-lift shippingbutton" href="https://www.fedex.com/content/dam/fedex/us-united-states/services/UN3373_fxcom.pdf" target="_blank" rel="noopener noreferrer" >IATA UN3373 Compliant Packaging</a><br></br><br></br>
		<h4 className="shippingsubtitle">All samples to be shipped internationally require two documents:</h4>
		<ul>
		<li>1. <b>A Commercial Invoice</b>. Blank invoices are usually provided by the courier service or your institution may have a template for all international business. A template is provided here if your institution does not have one.</li><br></br>
		<li><a className="testlinks button is-hoverable-button is-link shadow-lift shippingbutton" href={commInvoice} target="_blank" rel="noopener noreferrer" >Commercial Invoice</a></li><br></br>
		<li>2. <b>A Shipper's Declaration</b>. A short paper explaining the contents, their purpose and what risk they pose to the courier. A template is provided here if your institution does not have one.</li><br></br>
		<li><a className="testlinks button is-hoverable-button is-link shadow-lift shippingbutton" href={shipDec} target="_blank" rel="noopener noreferrer" >Shipper's Declaration</a></li><br></br>
		</ul>
		Samples missing completed versions of either document are subject to delays clearing US Customs. This can impact sample integrity and may lead to sample rejection. Please ensure documentation is complete prior to shipping. To foreign customers unfamiliar with the process of shipping samples internationally, we have some template forms available.</p><br></br><br></br><br></br>
	</div>

////////////////////////////////////////////////////////// PLACEHOLDERS ////////////////////////////////////////////////////////////////////////
export const small_lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt`

export const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lectus quam id leo in vitae turpis massa sed. Gravida cum sociis natoque penatibus. Nec feugiat nisl pretium fusce id velit ut tortor pretium. Volutpat diam ut venenatis tellus in metus vulputate. Fames ac turpis egestas integer. Volutpat lacus laoreet non curabitur gravida arcu. Pellentesque adipiscing commodo elit at. Molestie nunc non blandit massa enim nec. Pellentesque id nibh tortor id aliquet lectus proin nibh.
Ultrices in iaculis nunc sed augue lacus viverra vitae congue.
Quam nulla porttitor massa id neque aliquam.`


/////////////////////////////////////////// CONSULTING TEXT FOR SCHEDULING PAGE /////////////////////////////////////////////////////////////////
export const consulting_text =
	<>
		<h1 className={''} style={{fontSize: '30px', textAlign: 'center'}}>Clinical Genetic Consulting</h1>
		<br></br>
		<p className={''} ><i>Help with selecting the most appropriate testing type and understanding the genetic findings by board-certified geneticists and pathologists.</i></p>
		<p className={''}>Choosing the right genetic test can be confusing for even someone with medical training and research experience. The most important reason for this is that we have been living though a revolution in our understanding of genetic disease thanks to the revolution in technologies. Clinical application of these technologies has been faster than the bureaucracy surrounding modern medicine could keep up with.</p>
		<p className={''}>At Praxis Genomics, we provide a service to discuss the patients condition and past testing history and we offer our recommendations how to proceed to maximize the chances for a genetic diagnosis.</p>
		<p className={''}>Interpretation of the test report is another reason why many people feel uncomfortable with genetic testing. Our philosophy at Praxis Genomics is that we want to involve the patient's doctor, or the patient itself into discussion of the results in a way that one would discuss similar results with a family member. It is important for the patient to understand what the results say and do not say, and what is the level of certainty for finding the cause of their condition.</p>
		<p className={''}>Ultimately it comes down to familiarity with the technology, the science and the medical aspects of the condition that allow to give reliable and easy to understand interpretation. This is something that Praxis Genomics does best and takes particular pride in being able to do.</p>
	</>

export const consulting_text_array =
	{
		title: "Clinical Genetic Consulting",
		second: "Choosing the right genetic test can be confusing for even someone with medical training and research experience. Rapid clinical application of novel technologies created many testing approaches all with their relative strengths and weaknesses.",
		third: "Praxis Genomics is founded on the idea of combining different types of technologies to produce the best outcome for our patients. To achieve this goal, we will discuss with the patient or the referring physician the best testing options based on the patients presentation and prior testing history.",
		fourth: "Fear of not being able to interpret the testing report is another reason why many people feel uncomfortable with genetic testing. Praxis Genomics offers discussion of the report to make sure that the ordering physician and the patient understand the precise clinical significance of the reported findings. Familiarity with the technology, the science and the medical aspects of genetic conditions allows Praxis Genomics to give reliable and easy to understand explanation of the results."
	}

//////////////////////////////////////1st PARAGRAPH OF DESCRIPTION INTROS FOR TEST BOXES ////////////////////////////////////////////////////
//need to add some more once we have the description
const exomeReportingIntro = <>Our Expanded Exome test is based on Whole Genome Sequencing. This minimizes the number of false positive and false negative results seen in capture-based panel and exome studies and allows us to report out all known pathogenic variants irrespective of their location. Basic Exome is defined as coding regions +/- 100bps plus mitochondrial genome (SNV, deletions) and single exon resolution CNV calling; Expanded Exome adds repeat expansion assessment for all know repeat expansion associated conditions. <br></br><br></br><span className="is-italic has-text-link has-text-weight-semibold is-size-5"> This test can be upgraded to Whole Genome Sequencing (SRG511) and Optical Genome Mapping (OGM001-004) without providing additional patient sample.<br></br><br></br>The Expanded Exome -> Whole Genome upgrade architecture protects the customer financially since the upgrade from a negative exome to Expanded Exome or genome costs only the difference between the price of the respective tests and no new sample is required.</span></>

///////////////////////////////////////////////// PRAXIS OBJECTS, USED THE SERVICES ONE MOSTLY ///////////////////////////////////////////////////
export const praxisTestTypes = {
	aneuploidyTesting : {testCat: ["Constitutional"], title:"Aneuploidy Testing", to:"aneuploidy-testing", type:["CONSTITUTIONAL"]},
	basicExomeTest : {testCat: ["Constitutional"], title:"Basic Exome", to:"basic-exome-reporting", type:["CONSTITUTIONAL"]},
	exomeReportingTest : {testCat: ["Constitutional"], title:"Expanded Exome", to:"exome-reporting", type:["CONSTITUTIONAL"]},
	WholeGenSeqTest : {testCat: ["Constitutional", "Carrier"], title:"Whole Genome Sequencing", to:"whole-genome-sequencing", type:["CONSTITUTIONAL", "SOMATIC"]},
	SaphyrTest : {testCat: ["Constitutional", "Carrier"], title:"Optical Genome Mapping", to:"saphyr-optical-mapping", type:["CONSTITUTIONAL", "SOMATIC"]},
	lrWGS : {testCat: ["Constitutional"], title:"Whole Genome Sequencing (Long Read)*", to:"lr-whole-genome-sequencing", type:["CONSTITUTIONAL"]},
	lrMethylomeWGS : {testCat: ["Constitutional"], title:"Whole Genome Methylome Testing (Long Read)", to:"lr-methylome-testing", type:["CONSTITUTIONAL"]},
	extWholeGenTest : {testCat: ["Constitutional"], title:"External Whole Genome Data Analysis", to:"whole-genome-analysis", type:["CONSTITUTIONAL"]},
	transcriptomeTest : {testCat: ["Constitutional"], title:"Transcriptome", to:"transcriptome", type:["CONSTITUTIONAL", "SOMATIC"]},
	combinationTest : {testCat: ["Constitutional", "Carrier"], title:"Combination Testing", to:"combination-testing", type:["CONSTITUTIONAL", "SOMATIC"]},
	FSHDtesting: {testCat: ["Constitutional"], title: "FSHD Testing", to: "FSHD-testing", type:["CONSTITUTIONAL"]},
	heredityTest : {testCat: ["Heredity"], title:"Heredity & Relatedness", to:"heredity-test", type:["CONSTITUTIONAL"]},
	repeatExpansion: {testCat: ["Constitutional"], title: 'Repeat Expansion Sizing', to: 'repeat-expansion-sizing', type:["CONSTITUTIONAL"]},
	knownFamilialTest : {testCat: ["Constitutional"], title:"Known Familial Variant Testing", to:"known-familial-variant", type:["CONSTITUTIONAL"]}
}

// finished re-processing tests... 
export const services =  [
	{
		testCat: ["Constitutional", "Carrier"], 
		id: 'saphyr-optical-mapping',
		title: 'Optical Genome Mapping',
		intro: ogm_cg_intro, 
		description: ogm_cg_description, 
		sampleinfo: OGMSampleDetail,
		individualIntro: ogm_ci_intro,
		individualDescription: ogm_ci_description,
		coupleIntro: ogm_cc_intro,
		coupleDescription: ogm_cc_description,
		constitutionalTests: saphyrConstitutional, 
		individualTests: saphyrCarrierIndividual, 
		coupleTests: saphyrCarrierCouple, 
		pla: "0264U", 
		type: "CONSTITUTIONAL"
	},{
		testCat: ["Constitutional", "Carrier"],
		id: 'saphyr-optical-mapping',
		title: 'Optical Genome Mapping',
		intro: somaticOGMIntro, 
		description: somaticOGMDescription, 
		sampleinfo: OGMSampleDetail,
		constitutionalTests: somaticOGMTests,
		type: "SOMATIC"
	},{
		testCat: ["Constitutional", "Carrier"], 
		id:'whole-genome-sequencing',
		title:'Whole Genome Sequencing',
		intro: wgs_cd_intro, 
		description: wgs_cd_description, 
		individualIntro: wgs_ci_intro,
		individualDescription: wgs_ci_description,
		coupleIntro: wgs_cc_intro,
		coupleDescription: wgs_cc_description, 
		sampleinfo: OGMSampleDetail,
		constitutionalTests: wholeGenSeqConstitutional, 
		individualTests: wholeGenSeqCarrierIndividual, 
		coupleTests:wholeGenSeqCarrierCouple, 
		pla:"0265U", 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"], 
		id:'lr-whole-genome-sequencing',
		title:'Whole Genome Sequencing (Long Read)*',
		intro: lr_wgs_cd_intro, 
		description: lr_wgs_cd_description, 
		individualIntro: lr_wgs_ci_intro,
		individualDescription: lr_wgs_ci_description,
		// coupleIntro: lr_wgs_cc_intro,
		// coupleDescription: lr_wgs_cc_description, 
		sampleinfo: OGMSampleDetail,
		constitutionalTests: lr_wholeGenSeqConstitutional, 
		individualTests: wholeGenSeqCarrierIndividual, 
		coupleTests:wholeGenSeqCarrierCouple, 
		// pla:"0265U", 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"], 
		id:'lr-methylome-testing',
		title:'Whole Genome Methylome Testing (Long Read)',
		intro: methylome_wgs_cd_intro, 
		description: methylome_wgs_cd_description, 
		// individualIntro: methylome_wgs_ci_intro,
		// individualDescription: lr_wgs_ci_description,
		// coupleIntro: lr_wgs_cc_intro,
		// coupleDescription: lr_wgs_cc_description, 
		sampleinfo: OGMSampleDetail,
		constitutionalTests: methylomeWGSConstitutional, 
		// individualTests: wholeGenSeqCarrierIndividual,
		// coupleTests:wholeGenSeqCarrierCouple, 
		// pla:"0265U",
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"], 
		id:'aneuploidy-testing',
		title:'Aneuploidy Testing',
		intro: aneuploidy_cd_intro, 
		description: aneuploidy_cd_description,  
		sampleinfo: aneuploidySpecDetail,
		constitutionalTests: aneuploidyConstitutional, 
		// individualTests: aneuploidyConstitutional, 
		// coupleTests:wholeGenSeqCarrierCouple, 
		// pla:"0265U",
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional", "Carrier"],
		id:'whole-genome-sequencing', 
		title:'Whole Genome Sequencing', 
		intro: somaticWholeGenomeIntro, 
		description: somaticWholeGenomeDescription, 
		sampleinfo: OGMSampleDetail,
		constitutionalTests: somaticWGSTests,
		type:"SOMATIC"
	},{
		testCat: ["Constitutional", "Carrier"], 
		id:'combination-testing',
		title:'Combination Testing',
		intro: combinationIntro, 
		description: combinationDescription, 
		individualIntro: comb_ci_intro,
		individualDescription: comb_ci_description,
		coupleIntro: comb_cc_intro,
		coupleDescription: comb_cc_description,
		sampleinfo: combinationSampleDetail,
		constitutionalTests: combinationConstitutional, 
		individualTests: combinationCarrierIndividual, 
		coupleTests: combinationCarrierCouple, 
		pla:"0267U", 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional", "Carrier"],
		id:'combination-testing', 
		title:'Combination Testing', 
		intro: somaticCombinationIntro, 
		description: somaticCombinationDescription, 
		sampleinfo: combinationSampleDetail,
		constitutionalTests:somaticCombinationTests,
		type:"SOMATIC"
	},{
		testCat: ["Constitutional"], 
		id:'transcriptome',
		title:'Transcriptome',
		intro: transcriptomeIntro, 
		description:transcriptomeDescription , 
		sampleinfo:transcriptomeSampleDetail, 
		constitutionalTests:transcriptomeTests, 
		pla:"0266U", 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Heredity"], 
		id: 'heredity-test',
		title:"Heredity & Relatedness",
		intro: heredityTestIntro, 
		description: heredityTestDescription, 
		sampleinfo: genericSampleIntro, heredityTests:heredityTests, 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"], 
		id: 'FSHD-testing',
		title: "FSHD Testing",
		intro: fshdIntro, 
		description: fshd1Description, 
		sampleinfo: genericSampleIntro,
		constitutionalTests:FSHDTests, 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"], 
		id: 'repeat-expansion-sizing',
		title: 'Repeat Expansion Sizing',
		intro: repeatIntro, 
		description: repeatDescription, 
		sampleinfo: genericSampleIntro,
		constitutionalTests:repeatExpansionTests, 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"], 
		id:'known-familial-variant',
		title:'Known Familial Variant Testing',
		intro: knownFamilialIntro, 
		description: knownFamilialDescription, 
		sampleinfo: OGMSampleDetail,
		constitutionalTests: familialTests, 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"], 
		id:'basic-exome-reporting',
		title:'Basic Exome',
		intro: basicExomeIntro,
		description: basicExomeDescription, 
		sampleinfo: genericSampleIntro,
		constitutionalTests: basicExomeTests, 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"], 
		id:'exome-reporting',
		title:'Expanded Exome',
		intro: exomeReportingIntro, 
		description: exomeReportingDescription, 
		sampleinfo: genericSampleIntro,
		constitutionalTests: expandedExomeTests, 
		type:"CONSTITUTIONAL"
	},{
		testCat: ["Constitutional"],
		id:'transcriptome', 
		title:'Transcriptome', 
		intro: somaticTranscriptomeIntro, 
		description:somaticTranscriptomeDescription , 
		sampleinfo:transcriptomeSampleDetail, 
		constitutionalTests:somaticTranscriptomeTests,
		type:"SOMATIC"
	}
]

export const publications = [
	{
		link: "https://onlinelibrary.wiley.com/doi/10.1002/ajmg.a.63814",
		date: "July 2024",
		title: "Optical genome mapping with genome sequencing identifies subtelomeric Xq28 deletion and inserted 7p22.3 duplication in a male with multisystem developmental disorder",
		authors: "Jorge L. Rodriguez-Gil, Peter L. Nagy, Uta Francke",
		doi: "DOI: http://doi.org/10.1002/ajmg.a.63814",
		id: "DOI: http://doi.org/10.1002/ajmg.a.63814"
	},
	{
		link: "https://www.jmdjournal.org/article/S1525-1578(23)00002-8/fulltext",
		date: "Mar 2023",
		title: "Multisite Assessment of Optical Genome Mapping for Analysis of Structural Variants in Constitutional Postnatal Cases",
		authors: "M. Anwar Iqbal, Ulrich Broeckel, Brynn Levy, ... Aaron Bossler, Peter L. Nagy",
		doi: "DOI: https://doi.org/10.1016/j.jmoldx.2022.12.005",
		id: "DOI: https://doi.org/10.1016/j.jmoldx.2022.12.005"
	},
	{
		link: "https://genomebiology.biomedcentral.com/articles/10.1186/s13059-023-02882-4",
		date: "Feb 2023",
		title: "correctKin: an optimized method to infer relatedness up to the 4th degree from low-coverage ancient human genomes",
		authors: "Emil Nyerki, Tibor Kalmár, Oszkár Schütz, Rui M. Lima, Endre Neparáczki, Tibor Török & Zoltán Maróti",
		doi: "DOI: https://doi.org/10.1186/s13059-023-02882-4",
		id: "DOI: https://doi.org/10.1186/s13059-023-02882-4"
	},
	{
		link: "https://www.nature.com/articles/s41467-021-27685-8",
		date: "Jan 2022",
		title: "An intronic transposon insertion associates with a trans-species color polymorphism in Midas cichlid fishes",
		authors: "Claudius F. Kratochwil, Andreas F. Kautt, Alexander Nater, Andreas Härer, Yipeng Liang, Frederico Henning & Axel Meyer",
		doi: "DOI: https://doi.org/10.1038/s41467-021-27685-8",
		id: "https://doi.org/10.1038/s41467-021-27685-8"
	},
	{
		link: "https://onlinelibrary.wiley.com/doi/full/10.1111/mec.16466",
		date: "Apr 2022",
		title: "Molecular parallelism in the evolution of a master sex-determining role for the anti-Mullerian hormone receptor 2 gene (amhr2) in Midas cichlids",
		authors: "Camila L. Nacif, Claudius F. Kratochwil, Andreas F. Kautt, Alexander Nater, Gonzalo Machado-Schiaffino, Axel Meyer, Frederico Henning",
		doi: "DOI: 10.1111/mec.16466",
		id: "DOI: 10.1111/mec.16466"
	},
	{
		link: "https://www.cell.com/heliyon/fulltext/S2405-8440(22)03019-5?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS2405844022030195%3Fshowall%3Dtrue",
		date: "Nov 2022",
		title: "The genetic legacy of the Hunyadi descendants",
		authors: "Endre Neparáczki, Luca Kis, Zoltán Maróti, Bence Kovács, Gergely I.B. Varga, ... Péter L. Nagy, et al.",
		doi: "DOI: https://doi.org/10.1016/j.heliyon.2022.e11731",
		id:"DOI: https://doi.org/10.1016/j.heliyon.2022.e11731"
	},
	{
		link: "https://www.sciencedirect.com/science/article/abs/pii/S1673852722001795?via%3Dihub",
		date: "Aug 2022",
		title:"The archaeogenomic validation of Saint Ladislaus' relic provides insights into the Árpád dynasty's genealogy",
		authors: "Gergely I B Varga, L. A. Kristóf, K. Maár, L. Kis, O. Schütz, O. Váradi, B. Kovács, A. Ginguta, B. Tihanyi, Péter L Nagy et al.",
		doi: "DOI: 10.1016/j.jgg.2022.06.008",
		id:"DOI: 10.1016/j.jgg.2022.06.008"
	},
	{
		link: "https://www.sciencedirect.com/science/article/pii/S0960982222007321",
		date: "May 2022",
		title:"The genetic origin of Huns, Avars, and conquering Hungarians",
		authors: "Zoltán Maróti, Endre Neparáczki, Peter L. Nagy, Tibor Török, et al.",
		doi: "ISSN 0960-9822 DOI: 10.1016/j.cub.2022.04.093",
		id:"ISSN 0960-9822 DOI: 10.1016/j.cub.2022.04.093"
	},
	{
		link: "https://www.jci.org/articles/view/156125?utm_source=submission_site&utm_medium=email&utm_campaign=notice-of-publication",
		date: "Mar 2022",
		title:"Aerobic exercise elicits clinical adaptations in myotonic dystrophy type 1 patients independent of pathophysiological changes",
		authors: "Andrew I Mikhail, Peter L. Nagy, Katherine Manta, Nicholas Rouse, Alexander Manta, Sean Y Ng, Michael F Nagy, Paul Smith, Jian-Qiang Lu, Joshua P Nederveen, Vladimir Ljubicic, Mark A Tarnopolsky",
		doi: "PMID: 35316212 DOI: 10.1172/JCI156125",
		id:"PMID: 35316212 DOI: 10.1172/JCI156125"
	},
	{
		link: "https://www.thieme-connect.com/products/ejournals/abstract/10.1055/s-0041-1736610",
		date: "Nov 2021",
		title:"The Efficacy of Whole Genome Sequencing and RNA-Seq in the Diagnosis of Whole Exome Sequencing Negative Patients with Complex Neurological Phenotypes",
		authors: "Bianca Blake, Lauren I. Brady , Nicholas A. Rouse, Peter L. Nagy, Mark A. Tarnopolsky",
		doi: "DOI: 10.1055/s-0041-1736610",
		id:"DOI: 10.1055/s-0041-1736610"
	},
	{
		link: "https://www.cell.com/cell-reports/fulltext/S2211-1247(21)00476-9",
		date: "May 2021",
		title:"The histone H3K9M mutation synergizes with H3K14 ubiquitylation to selectively sequester histone H3K9 methyltransferase Clr4 at heterochromatin",
		authors: "Chun-Min Shan, et al., Peter L. Nagy ",
		doi: "Cell Reports 35 (7), 109137",
		id:"Cell Reports 35 (7), 109137"
	},
	{
		link: "bloodpaper",
		date: "Oct 2020",
		title:"Blood group typing from whole-genome sequencing data",
		authors: "J Paganini, Peter L. Nagy, N Rouse, P Gouret, J Chiaroni, C Picard, ...",
		doi: "Plos one 15 (11), e0242168",
		id:"BLOODPAPER"
	},
	{
		link: "arpadpaper",
		date: "June 2020",
		title:"Determination of the phylogenetic origins of the Árpád Dynasty based on Y chromosome sequencing of Béla the Third",
		authors: "PL Nagy, J Olasz, E Neparáczki, N Rouse, K Kapuria, S Cano, H Chen, ...",
		doi: "European Journal of Human Genetics 29 (1), 164-17 <br> <a className='is-size-7' href='../../assets/hungarianpaper.pdf'> (Hungarian)</a><a className='is-size-7' href='../../assets/russianpaper.pdf'> (Russian)</a></p>",
		id: "ARPAD"
	}
]

export const news =
[
	{
		"link": "https://www.completegenomics.com/service-providers/",
		"date": "Mar 2024",
		"title": "Praxis Genomics is now listed as a service provider for Complete Genomics",
		"id": "12"
	},
	{
		"link": "https://dnapodcast.com/episodes/2023/12/22/268-optical-genome-mapping-and-transcriptome-analysis-with-peter-nagy",
		"date": "Jan 2024",
		"title": "Dr. Nagy is featured on the DNA Today Podcast.",
		"id": "11"
	},
	{
		"link": "https://cnpg.comparenetworks.com/608616-A-new-paradigm-for-genomic-diagnosis-combining-optical-genome-mapping-whole-genome-sequencing-and-transcriptome-analysis/",
		"date": "May 2023",
		"title": "Dr. Nagy ASHG Talk - A new paradigm for genomic diagnosis: combining optical genome mapping, whole genome sequencing and transcriptome analysis",
		"id": "9"
	},
	{
		"link": "https://www.labroots.com/webinar/advantage-combining-short-read-genome-sequencing-optical-genome-mapping-transcriptome-analysis-const",
		"date": "May 2023",
		"title": "Dr. Nagy Labroots Talk - The Advantage of Combining Short Read Whole Genome Sequencing, Optical Genome Mapping and Transcriptome Analysis in Constitutional and Cancer Diagnostics",
		"id": "9"
	},
	{
		"link": "",
		"date": "May 2022",
		"title": "Praxis Genomics is now accepting Medicare and GA Medicaid.",
		"id": "8"
	},
	{
		"link": "praxisPLA",
		"date": "Oct 2021",
		"title": "Praxis Genomics Constitutional PLA codes effective as of Oct. 1st",
		"id": "7"
	},
	{
		"link": "somaticPLA",
		"date": "Oct 2021",
		"title": "Praxis Genomics PLA codes for SOMATIC Bionano Optical Genome Mapping (OGM) with Illumina Short Read (ISR) Genome and Transcriptome have been released.",
		"id": "6"
	},
	{
		"link": "dex",
		"date": "July 2021",
		"title": "Praxis Genomics recieves DEX codes for Expanded Exome",
		"id": "5"
	},
	{
		"link": "",
		"date": "July 2021",
		"title": "Praxis Genomics launches the Expanded Exome",
		"id": "4"
	},
	{
		"link": "bioscryb",
		"date": "April 2021",
		"title": "Praxis Lab recieves BioSkryb Certificate for ResolveDNA™ WGA Protocol",
		"id": "3"
	},
	{
		"link": "fshdSociety",
		"date": "May 2021",
		"title": "Praxis Genomics is now a partner of the FSHD Society",
		"id": "2"
	},
	{
		"link": "praxisarticle",
		"date": "Oct 2020",
		"title": "Praxis Genomics Bets on Bionano Optical Maps",
		"id": "1"
	}
]
