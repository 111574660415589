import React from 'react'


import '../CSS/HeroSection.css'
import coverVideo from '../../../src/sapphire4.mp4'
import { Link as ScrollLink } from "react-scroll"
import { Link } from 'react-router-dom'

import TestHelp from "./TestHelp.js"

class HeroSection extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			modalState : ' has-modal-off',
			popUpState : ' test-helper-container pop-up-off',
			videoLoaded: false
		}
		this.handleVideoLoaded = this.handleVideoLoaded.bind(this);
	}

	handleVideoLoaded () {
		this.setState({ videoLoaded: true });
	}

	toggleState(){
		if (this.state.modalState === ' has-modal-off'){
			this.setState({popUpState: ' test-helper-container pop-up-on'})
			this.setState({modalState: ' has-modal-on-lower-opacity'})
		} else {
			this.setState({popUpState: ' test-helper-container pop-up-off'})
			this.setState({modalState: ' has-modal-off'})
		}
	}

	render () {
		return (
			<>
				{/* <div id="modal-background" className={this.state.modalState}></div> */}
				<TestHelp
					passedFunction = {this.toggleState.bind(this)}
					popUpState = {this.state.popUpState}
					modalState = {this.state.modalState}
				/>
				<div className="background-video w-background-video upper-layer">
					<video 
						autoPlay={true}
						playsInline={true} 
						muted={true} 
						loop={true} 
						onLoadedData={this.handleVideoLoaded} // Event listener for video load
						className={this.state.videoLoaded ? 'fade-in' : ''} // Apply class on load
						src={coverVideo}>
					</video>
					<div className="hero-section-wrapper">
						<div className="hero-center-wrapper has-text-light">
							<h2 className="subtitle is-size-4-mobile is-size-2-tablet is-size-2-desktop has-text-white">
								{this.props.first}
							</h2>
							<div className="divider no-vis"></div>
							<h1 className="title has-text-light is-size-3-mobile is-size-1-tablet is-size-1-desktop">
								{this.props.second}
							</h1>
							<div className="divider no-vis"></div>
							<h1 className="siding subtitle is-size-4-tablet has-text-white-bis">
								{this.props.third}
								<br></br>
								{this.props.fourth}
							</h1>
							{/* <div className="padding-top">
								<div style={{fontSize:'1.2rem'}} className="is-family-primary has-text-weight-bold button is-link shadow-lift" onClick={this.toggleState.bind(this)}>Find The Right Test For You</div>
							</div> */}
							<div className="field is-grouped padding-top">
								<p className="control">
									<button className="button has-text-weight-bold shadow-lift is-link" onClick={this.toggleState.bind(this)}>
									  Find The Right Test For You
									</button>
								</p>
								<p className="control">
									<ScrollLink
										activeClass="is-selected"
										className="button has-text-weight-bold shadow-lift get-brochures-button"
										to="brochures"
										spy={true}
										smooth={true}
										offset={-150}
										duration={800}>
											Download our Brochures
									</ScrollLink>
								</p>
								<p className="control">
									<Link
										activeClass="is-selected"
										className="button has-text-weight-bold shadow-lift schedule-consult"
										to="/scheduling"
									>
											Schedule Consultation
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}



export default HeroSection
