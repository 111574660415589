import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import praxisPLA from "../../assets/code-info/praxisPLA.pdf"
import somaticPLA from "../../assets/code-info/Somatic-PLA.pdf"

export class TestLinks extends Component {
    render() {
        return (
                <div id="test-link-div" className={"column " + ((this.props.chosen_test === "") ? "is-hidden" : "fade-in")}>
                    <div className="center-text">
                        <Link className="testlinks button is-hoverable-button is-link shadow-lift" to="/scheduling">
                            Schedule Consultation
                        </Link>
                        <Link className="testlinks button is-hoverable-button is-link shadow-lift" to="/forms">
                            Add'l Forms
                        </Link>
                        {/* <a className="testlinks button is-hoverable-button is-link shadow-lift" target="_blank" rel="noopener noreferrer" href={dex}>
                            DEX Z-CODES™
                        </a> */}
                        <a className={"testlinks button is-hoverable-button is-link shadow-lift" + ((this.props.chosen_type !== "SOMATIC") ? "" : " is-hidden")}  target="_blank" rel="noopener noreferrer" href={praxisPLA}>
                            PLA Codes
                        </a>
                        <a className={"testlinks button is-hoverable-button is-link shadow-lift" + ((this.props.chosen_type !== "CONSTITUTIONAL") ? "" : " is-hidden")}  target="_blank" rel="noopener noreferrer" href={somaticPLA}>
                            PLA Codes
                        </a>
                        <Link className="testlinks button is-hoverable-button is-link shadow-lift" to="/shipping">
                            Shipping Resources
                        </Link>
                    </div>
                </div>
            )
    }
}

export default TestLinks
