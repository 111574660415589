import React from 'react'
import {praxisTestTypes} from './praxisData.js'

import { Link as ScrollLink } from "react-scroll"

import SimpleFooter from '../components/SimpleFooter'
import SubNavBar from "../components/SubNavBar"
import TestBox from './TestBox'
import TestCategory from './TestCategory.js'

import '../CSS/lucaTestList.css'

class TestList extends React.Component {

    state = {
        chosen_type: (typeof this.props.location.state === 'undefined') ? "CONSTITUTIONAL" : this.props.location.state.chosen_type,
        chosen_test: (typeof this.props.location.state === 'undefined') ? "Constitutional" : this.props.location.state.chosen_test,
        pre_testing: "DISEASE"
    }

    toggleType = (e) => {
        let type = e.target.textContent.trim()

        this.setState(prevState => ({
            chosen_type: type,
            chosen_test: "Constitutional"
        }))
    }

    toggleCategory = (e) => {
        let test = e.target.textContent.trim()

        var subtitle
        if (test.split(' ').length === 2){
            subtitle = test.split(' ')[1].toUpperCase()
        } else if (test.split(' ').length === 3){
            subtitle = test.split(' ')[2].toUpperCase()
        } else if (test.split(' ')[0] === "Heredity"){
            subtitle = ""
        } else {
            subtitle = "DISEASE"
        }

        if (test === "Diagnostic") {
            test = "Constitutional"
        }

        this.setState(prevState => ({
            chosen_test: test,
            pre_testing: subtitle
        }))
    }

    componentDidUpdate() {
        window.scrollTo(0, 0)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const scrollToTest = this.props.location?.state?.scroll_to;
        if (scrollToTest) {
            // Use react-scroll to scroll to the specified test
            this.scrollToTest(scrollToTest);
        }
    }

    componentWillUnmount() {
        window.scrollTo(0, 0)
    }

    getTests = () => {
        let chosen_test = this.state.chosen_test
        let chosen_type = this.state.chosen_type
        
        return Object.keys(praxisTestTypes).map((test,index) => {
            return <TestBox
                        key={test}
                        chosen_test={chosen_test}
                        chosen_type={chosen_type}
                        to={praxisTestTypes[test].to}
                        tests={praxisTestTypes[test].tests}
                        title={praxisTestTypes[test].title}
                    ></TestBox>
        })
    }

    scrollToTest = (testId) => {
        const Scroll = require('react-scroll');
        const scroller = Scroll.scroller;

        scroller.scrollTo(testId, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -150, // Adjust as needed
        });
    }

    TestLinksScrollToComponent = () => {

        let chosen = this.state.chosen_test.split(' ')[0]
        let type = this.state.chosen_type
        return Object.keys(praxisTestTypes).map(function(test){
            if (praxisTestTypes[test].testCat.includes(chosen) && praxisTestTypes[test].type.includes(type)){
                return <div className="test-box-button button is-hoverable-button shadow-lift">
                            <ScrollLink
                                activeClass="is-selected"
                                className="has-text-weight-semibold"
                                to={praxisTestTypes[test].to}
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={800}>
                                    {praxisTestTypes[test].title}
                            </ScrollLink>
                        </div>
            }
        })
    }

    render() {
        if (this.state.chosen_type === "CONSTITUTIONAL") {
            return (
                <>
                    <SubNavBar />
                    <section className="hero is-medium hero-section-wrapper tests-background-img">
                        <div className="hero-body hero-center-wrapper">
                            <h1 style={{ marginBottom:'1rem' }} className={"title is-size-2 faded-background"}>GENOMIC</h1>
                            <h2 className="title is-size-3 faded-background">TESTING</h2>
                            <div className="justify-content padding-top">
                                <a onClick={this.toggleType}> <h1 className={(this.state.chosen_type === "CONSTITUTIONAL" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="constitutional-button">CONSTITUTIONAL</h1></a>
                                <a onClick={this.toggleType}> <h1 className={(this.state.chosen_type === "SOMATIC" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="subcarrier2">SOMATIC</h1></a>
                            </div>
                            <div className="justify-content padding-top">
                                <a onClick={this.toggleCategory}> <h1 className={(this.state.chosen_test === "Constitutional" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="constitutional-button">Diagnostic</h1></a>
                                <a onClick={this.toggleCategory}> <h1 className={(this.state.chosen_test === "Carrier Individual" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="subcarrier2">Carrier Individual</h1></a>
                                <a onClick={this.toggleCategory}> <h1 className={(this.state.chosen_test === "Carrier Couple" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="subcarrier2" >Carrier Couple</h1></a>
                                <a onClick={this.toggleCategory}> <h1 className={(this.state.chosen_test === "Heredity" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="subcarrier2">Heredity</h1></a>
                            </div>
                        </div>
                    </section>
                    <TestCategory getTests={this.getTests} TestLinksScrollToComponent={this.TestLinksScrollToComponent} chosen_type={this.state.chosen_type} chosen_test={this.state.chosen_test} />
                    <SimpleFooter />
                </>
            )
        } else {
            return (
                <>
                    <SubNavBar />
                    <section className="hero is-medium hero-section-wrapper tests-background-img">
                        <div className="hero-body hero-center-wrapper">
                            <h1 style={{ marginBottom:'1rem' }} className={"title is-size-2 faded-background"}>GENOMIC</h1>
                            <h2 className="title is-size-3 faded-background">TESTING</h2>
                            <div className="justify-content padding-top">
                                <a onClick={this.toggleType}> <h1 className={(this.state.chosen_type === "CONSTITUTIONAL" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="constitutional-button">CONSTITUTIONAL</h1></a>
                                <a onClick={this.toggleType}> <h1 className={(this.state.chosen_type === "SOMATIC" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="subcarrier2">SOMATIC</h1></a>
                            </div>
                            <div className="justify-content padding-top">
                                <a onClick={this.toggleCategory}> <h1 className={(this.state.chosen_test === "Constitutional" ? "" : "faded-background") + " bumper-top-small has-text-weight-bold button is-hoverable-button is-link shadow-lift"} id="constitutional-button">Diagnostic</h1></a>
                            </div>
                        </div>
                    </section>
                    <TestCategory getTests={this.getTests} TestLinksScrollToComponent={this.TestLinksScrollToComponent} chosen_type={this.state.chosen_type} chosen_test={this.state.chosen_test} />
                    <SimpleFooter />
                </>
            )
        }

    }
}

export default TestList
