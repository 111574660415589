import React from "react"

class TeamMember extends React.Component {

    render () {
        return (
            <>
                <div className="column is-6 is-3-widescreen clickable" onClick={() => this.props.passedFunction()}>
                    <div className="level shadow-lift rounded-icon">
                        <div className="level-item">
                            <figure className="image bio-image-sizing">
                                <img className="is-rounded" src={this.props.img} alt=""></img>
                            </figure>
                        </div>
                    </div>
                    <h5 className="title is-5">{this.props.name}</h5>
                    <p className="subtitle is-6">{this.props.title}</p>
                </div>
            </>
        )
    }
}

export default TeamMember