import React from 'react'
import '../CSS/CredationSection.css'
// import cap from "../../assets/CapAcc.png"
import cap from "../../assets/certs/CapAcc.gif"
import clia from "../../assets/certs/CliaAcc.png"

import dch from "../../assets/icons/dch_logo.jpg"
import pgcl from "../../assets/licenses/PGCLL.pdf"

import nano from "../../assets/certs/BioNanoCert.png"

import capcert from "../../assets/certs/capcert.png"
import cliacert from "../../assets/certs/CLIAcert.png"
import csppcert from "../../assets/certs/csppcert.png"

import rilicense from "../../assets/licenses/RI Out of State Lab license LCO01558.pdf"
import palicense from "../../assets/licenses/PA laboratory permit.pdf"
import calicense from "../../assets/licenses/calabpermit.pdf"
import mdlicense from "../../assets/licenses/MD State Laboratory Permit.png"

import paDoH from "../../assets/licenses/paDoH.png"
import riDoH from "../../assets/licenses/riDoH.png"
import caDoH from "../../assets/licenses/caDoH.png"
import mdDoH from "../../assets/licenses/mdDoH.webp"

function CredationSection(props) {
    return (
        <>
        <div className="accredition-section">
            <div className="simple-padding logo-section">
                <div className="inline cliaAcc">
                    <a href={capcert} target="_blank" rel="noopener noreferrer"> <img className="accredition-img" alt="Cap Certificate" src={cap}></img></a>
                    <p className="absolute">CAP #: 8743866</p>
                </div>
                <div className="inline capAcc">
                    <a href={cliacert} target="_blank" rel="noopener noreferrer"><img className="accredition-img" alt="CLIA Certificate" src={clia}></img></a>
                    <p className="absolute">CLIA ID #: 11D2178215</p>
                </div>
                <div className="inline capAcc">
                    <a href={csppcert} target="_blank" rel="noopener noreferrer"><img className="accredition-img third-acc" alt="CSPP Certificate" src={nano}></img></a>
                    <p></p>
                </div>
                <div className="inline dch_special">
                    <a href={pgcl} target="_blank" rel="noopener noreferrer"><img className="dch-img" alt="Praxis Genomics Clia Accredition" src={dch}></img></a>
                    <p></p>
                </div>
            </div>
        </div>
        <div className='subtle-divider'></div>
        <div className="accredition-section">
            <br></br>
            <div className="simple-padding logo-section">
                <div className="inline capAcc">
                    <a href={palicense} target="_blank" rel="noopener noreferrer"><img className="accredition-img third-acc" alt="CSPP Certificate" src={paDoH}></img></a>
                    <p></p>
                </div>
                <div className="inline">
                    <a href={rilicense} target="_blank" rel="noopener noreferrer"><img className="accredition-img" alt="Praxis Genomics Clia Accredition" src={riDoH}></img></a>
                    <p></p>
                </div>
                <div className="inline">
                    <a href={calicense} target="_blank" rel="noopener noreferrer"><img className="accredition-img" alt="Praxis Genomics Clia Accredition" src={caDoH}></img></a>
                    <p></p>
                </div>
                <div className="inline">
                    <a href={mdlicense} target="_blank" rel="noopener noreferrer"><img className="accredition-img" alt="Praxis Genomics Clia Accredition" src={mdDoH}></img></a>
                    <p></p>
                </div>
            </div>
        </div>
        </>
    )
}

export default CredationSection
