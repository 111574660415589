import React, { Component } from 'react'

// import '@fortawesome/fontawesome-free/css/all.min.css';
import '../CSS/NewsSection.css'

import praxisarticle from "../../assets/papers/praxisarticle.pdf"
import arpadpaper from "../../assets/papers/arpadpaper.pdf"
import bloodpaper from "../../assets/papers/bloodgrouptyping.pdf"

import russianpaper from "../../assets/papers/russianpaper.pdf"
import hungarianpaper from "../../assets/papers/hungarianpaper.pdf"
import fshdSociety from "../../assets/certs/FSHDSocPraxis.pdf"
import bioscryb from "../../assets/certs/bioscryb.pdf"

import dex from "../../assets/code-info/dexcodes.82621.pdf"

import praxisPLA from "../../assets/code-info/praxisPLA.pdf"
import somaticPLA from "../../assets/code-info/Somatic-PLA.pdf"

import {Link} from 'react-router-dom'
import {publications, news} from '../views/praxisData.js'

class NewsSection extends Component {

    state = {
		news: {
			currentPage: 1,
			itemsPerPage: 5,
			totalPages: Math.ceil(news.length / 5),
			nextPageButtonDisabled: false,
			prevPageButtonDisabled: true
		},
		publications: {
			currentPage: 1,
			itemsPerPage: 5,
			totalPages: Math.ceil(publications.length / 5),
			nextPageButtonDisabled: false,
			prevPageButtonDisabled: true
		}
	}

	fileMappings = {
		fshdSociety: fshdSociety,
		dex: dex,
		bioscryb: bioscryb,
		praxisPLA: praxisPLA,
		somaticPLA: somaticPLA,
		praxisarticle: praxisarticle
	}

	nextPage = (viewName) => {
		this.setState(prevState => {
			const view = prevState[viewName];
			const newCurrentPage = view.currentPage < view.totalPages ? view.currentPage + 1 : view.currentPage;
			return {
				[viewName]: {
					...view,
					currentPage: newCurrentPage
				}
			};
		}, () => {
			this.updatePrevNextButtons(viewName);
		});
	}

	prevPage = (viewName) => {
		this.setState(prevState => {
			const view = prevState[viewName];
			const newCurrentPage = view.currentPage > 1 ? view.currentPage - 1 : view.currentPage;
			return {
				[viewName]: {
					...view,
					currentPage: newCurrentPage
				}
			};
		}, () => {
			this.updatePrevNextButtons(viewName);
		});
	}

	updatePrevNextButtons = (viewName) => {
		this.setState(prevState => {
			const view = prevState[viewName];
			return {
				[viewName]: {
					...view,
					nextPageButtonDisabled: view.currentPage === view.totalPages,
					prevPageButtonDisabled: view.currentPage === 1
				}
			};
		});
	}


	renderPapers = () => {
		const { currentPage, itemsPerPage } = this.state.publications;
		// Calc the correct slice range
		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return publications.slice(startIndex, endIndex).map(paper => {
			if (paper.id === "ARPAD") {
				paper.link = arpadpaper;
				paper.doi = <p>European Journal of Human Genetics 29 (1), 164-172 <br></br> <a className="is-size-7" href={hungarianpaper}> (Hungarian)</a><a className="is-size-7" href={russianpaper}> (Russian)</a></p>;
			} else if (paper.id === "BLOODPAPER") {
				paper.link = bloodpaper;
			}
			return (
				<div className="bottom-border" key={paper.id}>
					<a href={paper.link} target="_blank" rel="noopener noreferrer" className="post has-text-black">
						<div className="post-row is-size-6-desktop is-size-5-mobile">
							<time className="">{paper.date}</time>
							<h1 className="">
								{paper.title}
								<p className="subtitle is-size-7 is-size-8-mobile">{paper.authors}<br></br>
									{paper.doi}
								</p>
							</h1>
						</div>
					</a>
				</div>
			);
		});
	}

	renderNewsItem = (news_item) => {
		if (news_item.id === "4") {
			return (
				<div className='bottom-border'>
					<Link
						key={news_item.id}
						className="post has-text-black"
						to={{
							pathname: "/test-list",
							state: {
								chosen_type: "CONSTITUTIONAL",
								chosen_test: "Constitutional",
								pre_testing: "DISEASE"
							},
						}}
					>
						<div className="post-row is-size-6-desktop is-size-5-mobile">
							<time>{news_item.date}</time>
							<h1>{news_item.title}</h1>
						</div>
					</Link>
				</div>
			);
		} else {
			const link = news_item.link ? (this.fileMappings[news_item.link] || news_item.link) : null;
			return link ? (
				<div className='bottom-border'>
					<a key={news_item.id} href={link} target="_blank" rel="noopener noreferrer" className="post has-text-black">
						<div className="post-row is-size-6-desktop is-size-5-mobile">
							<time>{news_item.date}</time>
							<h1>{news_item.title}</h1>
						</div>
					</a>
				</div>
			) : (
				<div className='bottom-border'>
					<a key={news_item.id} href={link} className="post has-text-black">
						<div className="post-row is-size-6-desktop is-size-5-mobile">
							<time>{news_item.date}</time>
							<h1>{news_item.title}</h1>
						</div>
					</a>
				</div>
			);
		}
	}

	renderNews = () => {
		const { currentPage, itemsPerPage } = this.state.news;
		// Calc the correct slice range
		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return news.slice(startIndex, endIndex).map(this.renderNewsItem);
	}

    render () {
        return (
            <>
                <div className="container" id="section6">
                    <div className="columns add-padding-bottom2x">
					<div className="column extra-padding">
						<h2 className="title is-upper-alpha is-size-3-mobile is-size-1 is-size-1-tablet is-size-1-desktop has-text-centered section-title">Publications</h2>
						<div className="is-shady fit-width-mod">
							{this.renderPapers()}
                            <div className="pagination-button-div is-centered">
                                <button className='pagination-previous' disabled={this.state.publications.prevPageButtonDisabled} onClick={() => this.prevPage('publications')}>
                                    <span className='icon'>
                                        <i className="fas fa-arrow-left"></i>
                                    </span>
                                </button>
                                <button className='pagination-next' disabled={this.state.publications.nextPageButtonDisabled} onClick={() => this.nextPage('publications')}>
                                    <span className='icon'>
                                        <i className="fas fa-arrow-right"></i>
                                    </span>
                                </button>
							</div>
                        </div>
					</div>
					<div className="column extra-padding">
						<h2 className="title is-upper-alpha is-size-3-mobile is-size-1 is-size-1-tablet is-size-1-desktop has-text-centered section-title">News</h2>
						<div className="is-shady fit-width-mod">
							{this.renderNews()}
                            <div className="pagination-button-div is-centered">
                                <button className='pagination-previous' disabled={this.state.news.prevPageButtonDisabled} onClick={() => this.prevPage('news')}>
                                    <span className='icon'>
                                        <i className="fas fa-arrow-left"></i>
                                    </span>
                                </button>
                                <button className='pagination-next' disabled={this.state.news.nextPageButtonDisabled} onClick={() => this.nextPage('news')}>
                                    <span className='icon'>
                                        <i className="fas fa-arrow-right"></i>
                                    </span>
                                </button>
							</div>
						</div>
					</div>
                    </div>
                </div>
            </>
        )
    }
}

export default NewsSection
