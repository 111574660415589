import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/ServicesSection.css'

import stockConsult from '../../assets/images/stockConsult.png'
import stockTesting from '../../assets/images/stockTesting.png'
import stockResearch from '../../assets/images/stockResearch.png'

import bifold from '../../assets/brochures/PraxisGenomics-BiFold-032224.pdf'
import trifold from '../../assets/brochures/PraxisGenomics-TriFold-032224.pdf'
import testlist from '../../assets/brochures/PraxisGenomics-TestList-032224.pdf'

import BrochureEmail from '../components/BrochureEmail'

function ServicesSection() {
    return (

        <>
        <div className="container services-special section" id="section2">
            <div className="">
                <h2 className="no-margin-bottom title is-upper-alpha is-size-3-mobile is-size-1 is-size-1-tablet is-size-1-desktop has-text-centered">Services</h2>
            </div>
            <div id="services-columns" className="columns services-padding-top">
                <div className="column is-4">
                    <div className="card is-shady">
                        <div className="card-image has-text-centered">
                            <img src={stockConsult} alt='A doctor offering counsel to a patient.'></img>
                        </div>
                        <div className="card-content">
                            <div className="content">
                                <h3>Clinical Genetic Consulting</h3>
                                <p className="is-size-6">Board-certified geneticists and pathologists will help select the appropriate tests and explain  the significance of the genetic findings</p>
                                <Link
                                  to="/scheduling"
                                  className="bottom-fixed has-text-weight-bold button is-link shadow-lift"
                                >
                                   Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div  className="service-card-1 card is-shady">
                        <div className="card-image has-text-centered">
                            <img src={stockTesting} alt="Scientists in lab with pipettes."></img>
                        </div>
                        <div className="card-content">
                            <div className="content fix">
                                <h3>Clinical Genetic Testing</h3>
                                <p className="is-size-6">Combination of Optical Genome Mapping, Whole Genome and Transcriptome Sequencing provide exceptional diagnostic sensitivity and accuracy</p>
                                <Link
                                  to="/test-list"
                                  className="bottom-fixed has-text-weight-bold button is-link shadow-lift"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="service-card-2 card is-shady">
                        <div className="card-image has-text-centered">
                            <img src={stockResearch} alt="Scientists performing tests in lab."></img>
                        </div>
                        <div className="card-content">
                            <div className="content fix">
                                <h3>Research</h3>
                                <p className="is-size-6">Custom application of  Optical Genome Mapping, Whole Genome and Transcriptome Sequencing for all species</p>
                                <Link
                                  to="/research"
                                  className="bottom-fixed has-text-weight-bold button is-link shadow-lift"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BrochureEmail pdfUrl1={bifold} pdfUrl2={trifold} pdfUrl3={testlist} />
        </div>
        </>
    )
}

export default ServicesSection;
