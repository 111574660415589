import React from "react"

import SubNavBar from "../components/SubNavBar"

class PaymentInfoPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render () {
        return (
            <>
                <SubNavBar />
                <section className="hero is-medium is-primary is-bold">
                    <div className="hero-body payment-page-bg-img">
                        <div className="container">
                            <h1 className="background-text is-size-4-mobile">Convenient and secure direct billing options.</h1>
                            <h1 className="background-text is-size-4-mobile">Electronic invoices delivered via email.</h1>
                            <h1 className="background-text is-size-4-mobile">Powered by Intuit.</h1>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <section className="tile is-parent">
                        <div className="tile is-6 is-parent">
                            <article className="tile is-child box">
                            <p className="title">Credit/Debit Card</p>
                            <p className="subtitle">Quickbooks Payments</p>
                            <div className="content">
                                <p>Convenient and flexible. We send you an invoice, you input your information, and your payment is processed by Intuit. </p>
                                <p>Payment can be arranged via card for balances up to $5,000. A surcharge of 3.5% will be applied to credit card payments.</p>
                            </div>
                            </article>
                        </div>
                        <div className="tile is-6 is-parent">
                                <article className="tile is-child box">
                                <p className="title">ACH / Bank Transfer / eCheck</p>
                                <div className="content">
                                    <p>Avoid high transaction fees by a bank-to-bank transfer. Wire instructions will be provided on the invoice.</p>
                                </div>
                                </article>
                        </div>
                        {/* <div className="tile is-6 is-parent">
                            <article className="tile is-child box">
                            <p className="title">Nine</p>
                            <p className="subtitle">Subtitle</p>
                            <div className="content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare magna eros, eu pellentesque tortor vestibulum ut. Maecenas non massa sem. Etiam finibus odio quis feugiat facilisis.</p>
                            </div>
                            </article>
                        </div> */}
                    </section>
                </div>
            </>
        )
    }
}

export default PaymentInfoPage