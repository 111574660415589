import React from "react"

import AboutBlurb from "./AboutBlurb.js"
import TeamMember from "./TeamMember.js"

import aurel from "../../assets/headshots/aurel.png"
import tycko from "../../assets/headshots/tyco.png"
import sheffield from "../../assets/headshots/sheffield.png"
import worm from "../../assets/headshots/worman.png"
import shelanski from "../../assets/headshots/shelanski.png"
import underhill from "../../assets/headshots/underhill.png"
import uta from "../../assets/headshots/uta.png"

const team =  [
    {id: 0, name:'Ben Tycko, MD, PhD', title: '', img: tycko, link:"https://scholar.google.com/citations?user=Eyw0v-QAAAAJ&hl=en",blurb: "A member of the Center for Discovery & Innovation (CDI) of Hackensack Meridian Health (HMH), where he directs the Division of Genetics & Epigenetics. He is also a member of the John Theurer Cancer Center (JTCC) at Hackensack University Medical Center, and a Program Leader in the JTCC consortium with the Lombardi Comprehensive Cancer Center of Georgetown University. Dr. Tycko graduated from NYU School of Medicine with an MD and PhD in 1984. He did residency and post-doctoral training in pathology at Stanford, followed by an instructorship at Harvard, after which he joined Columbia University in 1991, progressing to a tenured professorship, which he held until 2018, when he moved to the HMH-CDI. For three decades Dr. Tycko's research group has had continuous NIH funding for their work on genetics and epigenetics in human development and disease. Starting with pioneering studies of genomic imprinting in human development and cancer, his lab's work evolved into combined genomic and epigenomic mapping to understand cis- and trans-acting genetic-epigenetic interactions, and to pinpoint functional genetic variants that underlie inter-individual differences in disease risk."},
    {id: 1, name:'Peter Underhill, PhD', title: '', img:underhill, link:"https://academic.microsoft.com/author/2302782928/publication/search?q=Peter%20A.%20Underhill&qe=Composite(AA.AuId%253D2302782928)&f=&orderBy=0&paperId=1523562895", blurb: "Peter A. Underhill, PhD (retired) served 27 years as a staff researcher in the Department of Genetics, Stanford University School of Medicine, California. His research focused on deciphering patterns of genetic affinity, substructure and the histories of human populations. He has published widely. In 1995 he co-invented with Peter J. Oefner, DHPLC, a then efficient technology, that accelerated the discovery of nucleotide substitutions in the human genome. His research contributed ground-breaking insights into the global scaffold of human Y chromosome DNA sequence diversification.  This foundational framework ultimately pointed the way, via the agency of next generation sequencing, to the fulfillment of an unequivocal, spatially detailed, time-calibrated genealogy charting the origins, affinities and demography of contemporary populations as well as aspects of individual uniqueness."},
    {id: 2, name:'Val Sheffield, MD, PhD', title: '', img:sheffield, link:"https://scholar.google.com/citations?user=qTI2cXYAAAAJ&hl=en", blurb: "Dr. Val Sheffield is Professor of Pediatrics - Medical Genetics and Genomics at the University of Iowa. He received his bachelor of science degree in zoology from Brigham Young University and his M.D. and Ph.D. degrees from the University of Chicago. Dr. Sheffield completed a pediatric residency, a medical genetics fellowship and postdoctoral work at the University of California, San Francisco. Sheffield’s lab is interested in identifying and understanding the function of genes which cause a variety of human disorders. Their research efforts have focused on the molecular genetics of monogenic disorders, as well as polygenic and multifactorial disorders. Their research efforts have resulted in the mapping of many different disease loci. Complex genetic disorders currently under investigation in Sheffield’s laboratory include hypertension, obesity, congenital heart disease and autism."},
    {id: 3, name:'Howard J. Worman, MD', title: '', img:worm, link:"https://scholar.google.com/citations?user=tOA_jGQAAAAJ&hl=en", blurb:"Dr. Worman Professor of Medicine and Pathology and Cell Biology at Columbia University. Dr. Worman received a BA from Cornell University and MD from the University of Chicago. He trained in internal medicine at New York Hospital-Cornell Medical Center and did postdoctoral research in cell biology at Rockefeller University. He then obtained clinical training in liver diseases at the Mount Sinai Medical Center. Dr. Worman is internationally recognized for his research in on the molecular genetics of rare inherited diseases, cell biology and hepatology. He is an author on over 200 scientific publications. Dr. Worman has is a member of the American Society for Clinical Investigation and Association of American Physicians, professional organizations of leading senior physician scientists who are competitively selected."},
    {id: 4, name:'Michael L. Shelanski, MD, PhD', title: '', img:shelanski, link:"https://www.pathology.columbia.edu/profile/michael-l-shelanski-md#research", blurb:"Dr. Shelanski is a cell biologist and neuropathologist. He attended Oberlin College and received his MD and PhD degrees from the University of Chicago. He served on the faculties of Albert Einstein College of Medicine and Harvard Medical School before assuming the Chair of Pharmacology at NYU in 1978. He moved to the Vagelos College of Physicians and Surgeons at Columbia in 1987 where he served as the Delafield Professor and Chairman of the Department of Pathology and Cell Biology until 2015. Together with Dr. Richard Mayeux he founded Alzheimer’s Disease Research Center at Columbia and served as its Director for almost 25 years. He led the Medical Scientist Training Programs (joint MD and PhD programs) at both NYU and at the College of Physicians and Surgeons. He a member of numerous advisory and editorial boards and a member of the National Academy of Medicine and of the Association of American Physicians. Dr. Shelanski’s work on identification and purification of tubulin and the characterization of the neurofilament proteins are at the foundation of our understanding of how cells make and keep their shape and organize their internal functions."},
    {id: 5, name:'Uta Francke, MD', title: '', img: uta, link:"https://scholar.google.com/citations?user=1hY2IZ0AAAAJ&hl=en", blurb:"Uta Francke has been a professor of Genetics (emerita since 2010) and Pediatrics (active) at Stanford University School of Medicine since 1989, and before that was a faculty member in Genetics at Yale University. Her research has ranged from chromosome identification and gene mapping in human and mouse to the discovery of genes involved in heritable disorders, studies of their functions and of disease-causing mechanisms. Applying genomic technologies to mammalian genetics research, her laboratory developed mouse models for human microdeletion syndromes. Dr. Francke has authored over 500 peer-reviewed scientific publications and has been elected Member of the National Academy of Medicine and Fellow of the American Association for Advancement of Science and of the American Academy of Arts and Sciences. She is a past-president of the American Society for Human Genetics and of the International Federation of Human Genetics Societies, and a founding member of the American College of Medical Genetics. Dr. Francke holds an M.D. degree from the Ludwig-Maximilinas-Universität of Munich, Germany. She is board-certified by the American Board of Pediatrics, and by the American Board of Medical Genetics in Clinical Genetics, Molecular Genetics and Cytogenetics. Dr. Francke teaches medical and molecular genetics, and practices clinical genetics, at Stanford. Being very interested in facilitating the public understanding and clinical application of genomic discoveries, she served as a consultant to the editorial team of 23andMe, followed by a part-time appointment from 2010-2013."},
    {id: 6, name:'Mark Aurel Nagy, MD, PhD', title: '', img: aurel, link:"https://scholar.google.nl/citations?user=h7yBUBwAAAAJ&hl=en", blurb:'Aurel Nagy, PhD is currently completing his MD training in the Health Sciences and Technology program of Harvard Medical School and MIT. Aurel graduated with a BS in physics and neuroscience from Brown University. His PhD research employed cutting edge next generation sequencing technologies to 1) investigate the role of repressive mechanisms in regulating activity-dependent transcription in the brain, and 2) explore how the diverse cell types in the brain coordinately respond to sensory stimulus through single cell sequencing-based approaches. These works, completed with the overarching goal of deepening our understanding of the fundamental workings of the interplay between the brain and our environment, were published in the journals Neuron and Nature Neuroscience.'}
  ]

class AdvisoryBoard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalState : ' has-modal-off',
            popUpState : ' bio-pop-up pop-up-off',
            bioName : '',
            bioTitle : '',
            bioBlurb : '',
            bioImg : '',
            bioLink : ''
        }
    }

    toggleState(){
        if (this.state.modalState === ' has-modal-off'){
            this.setState({popUpState: 'bio-pop-up pop-up-on'})
            this.setState({modalState: ' has-modal-on'})
        } else {
            this.setState({popUpState: ' bio-pop-up pop-up-off'})
            this.setState({modalState: ' has-modal-off'})
        }
    }

    setBio(index){
        this.setState({bioName: team[index].name})
        this.setState({bioTitle: team[index].title})
        this.setState({bioBlurb: team[index].blurb})
        this.setState({bioImg: team[index].img})
        this.setState({bioLink: team[index].link ? team[index].link : "#"})

        this.toggleState()
    }

    render () {
        return (
            <>
            <div id="modal-background" className={this.state.modalState}></div>
            <AboutBlurb 
                passedFunction = {this.toggleState.bind(this)}
                popUpState = {this.state.popUpState}
                name = {this.state.bioName}
                title = {this.state.bioTitle}
                blurb = {this.state.bioBlurb}
                img = {this.state.bioImg}
                link = {this.state.bioLink}
            />
            <div className="container add-padding-bottom2x" id="section3">
                <div className="add-padding-bottom2x">
                    <h2 className="no-margin-bottom title  is-upper-alpha is-size-3-mobile is-size-1 is-size-1-tablet is-size-1-desktop has-text-centered">Scientific Advisory Board</h2>
                </div>
                <section className="">
                    <div className="container has-text-centered">
                        <div className="columns is-centered is-multiline">
                            {team.map((member, index) =>
                                <TeamMember
                                    key = {index}
                                    passedFunction = {() => this.setBio(member.id)}
                                    name = {member.name}
                                    title = {member.title}
                                    img = {member.img}                                     
                                />
                            )} 
                        </div>
                    </div>
                </section>
                <div className="section">
                    <div className="is-size-4-desktop is-size-4-tablet justify-text">
                        <h1> For decades, our founders have been pioneers in the development of modern genome analysis techniques and informatics tools for clinical applications.  They have been among the first in the country to introduce clinical microarray testing, to offer combined clinical DNA and RNA analysis for patients with pediatric malignancies and have been first in the world to introduce clinical grade whole-exome-based carrier testing. <br></br><br></br> <span className="has-text-weight-semibold">Praxis Genomics LLC</span> now builds on these accomplishments and pushes the boundaries of Molecular Medicine further to provide a genetic diagnosis for an ever-increasing percentage of patients.</h1>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default AdvisoryBoard