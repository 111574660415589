import React from 'react'
import '../CSS/SubHero.css'

function SubHero() {
    return (
        <section className="upper-layer is-relative">
            <a target="_blank" rel="noopener noreferrer" href="https://www.completegenomics.com/service-providers/">
            <div className="box cta extra-margin-bottom">
                <p className="has-text-link has-text-centered is-size-5-desktop is-size-6-tablet is-size-8-mobile hover-text text-lift">
                    <span className="tag is-primary padding-right is-size-5-desktop is-size-6-tablet is-size-8-mobile">News</span>
                    Praxis Genomics is now listed as a service provider for <a target="_blank" rel="noopener noreferrer" href="https://www.completegenomics.com/service-providers/">Complete Genomics</a>.
                </p>
            </div>
            </a>
        </section>
    )
}

export default SubHero
