import React from 'react'
import '../CSS/NavBar.css'

import blueStripes from "../../assets/images/blue-strips.png"

import logo from "../../assets/images/Logotag3.png"
import { Link as ScrollLink, animateScroll as scroll} from "react-scroll"
import {Link} from 'react-router-dom'

class NavBar extends React.Component{

	constructor(props) {
		super(props)
		this.state = {
			NavBarIsActive : ""
		}
	}

	toggleState(){
		if (this.state.NavBarIsActive === ' is-active'){
			this.setState({NavBarIsActive: ''})
		} else {
			this.setState({NavBarIsActive: ' is-active'})
		}
	}

	scrollToTop = () => {
		scroll.scrollToTop();
	};

	render() {
		return (
			<>
			<div className="blue-strip-container">
				<img src={blueStripes} alt="bluestripes" className="blue-strip-navbar" />
			</div>
			<header className="hero is-info is-medium is-bold is-above-all">
				<div className="hero-head">
					<nav className="navbar">
						<div className="container">
							<div className="navbar-brand">
								<a className="navbar-item" href="/" onClick={this.scrollToTop}>
									<img
										 src={logo}
										 alt="Logo"
									/>
								</a>
								<span className={"navbar-burger burger" + this.state.NavBarIsActive} onClick={this.toggleState.bind(this)}>
										<span></span>
										<span></span>
										<span></span>
								</span>
							</div>
							<div id="navbarMenu" className={"navbar-menu" + this.state.NavBarIsActive}>
								<div className="navbar-end">
									<div className="tabs is-right">
										<ul>
											<li>
												<ScrollLink
													activeClass="is-selected"
													className="has-text-weight-semibold"
													to="section2"
													spy={true}
													smooth={true}
													offset={-70}
													duration={500}
												>
													Services
												</ScrollLink>
											</li>
											<li>
												<Link
													to="/test-list"
													className="has-text-weight-semibold"
												>
													Tests
												</Link>
											</li>
											<li>
												<ScrollLink
													activeClass="is-selected"
													className="has-text-weight-semibold"
													to="section3"
													spy={true}
													smooth={true}
													offset={-120}
													duration={500}
												>
													About Us
												</ScrollLink>
											</li>
											<li>
												<ScrollLink
													activeClass="is-selected"
													className="has-text-weight-semibold"
													to="section6"
													spy={true}
													smooth={true}
													offset={-120}
													duration={500}
												>
													News
												</ScrollLink>
											</li>
											<li>
												<ScrollLink
													activeClass="is-selected"
													className="has-text-weight-semibold"
													to="section1"
													spy={true}
													smooth={true}
													offset={-70}
													duration={500}
												>
													Technology
												</ScrollLink>
											</li>
											<li>
												<ScrollLink
													activeClass="is-selected"
													className="has-text-weight-semibold"
													to="section4"
													spy={true}
													smooth={true}
													offset={-70}
													duration={500}
												>
													Contact Us
												</ScrollLink>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</nav>
				</div>
			</header>
			</>
		)
	}
}

export default NavBar
