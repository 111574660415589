import React, { Component } from 'react'
import TestLinks from './TestLinks'
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import {somaticTestingIntro, somaticTestingDescription, constitutionalIntro, constitutionalDiagnosticDescription, CarrierCoupleDescription, CarrierIndividualDescription, heredityIntro, heredityDescription, indCarrierGeneralDescription, coupCarrierGeneralDescription} from './praxisData'

export class TestCategory extends Component {

	state = {
		isActive: false
	}

	toggleReadMore = () => {
		this.setState(prevState => ({
			isActive: !prevState.isActive
		}))
	}

	render() {
		return (
			<>
			<div className="container">
				<div className="columns is-desktop responsive-section">
					<div id="test-type" className={"column is-three-quarters-desktop " + ((this.props.chosen_test === "") ? "is-hidden" : "fade-in")} >
					{/* <p>{this.props.chosen_test}</p> */}
						{(() => {
							if (this.props.chosen_type === "CONSTITUTIONAL") {
								if (this.props.chosen_test === "Constitutional"){
									return (
										<>
											<div>{constitutionalIntro}</div>
											<div className={(this.state.isActive ? 'read-more is-active' : 'read-more')}>{constitutionalDiagnosticDescription}</div> 
											<br></br>
											{/* <p className='is-size-5 is-italic has-text-weight-bold'>* Submitting Healthcare Providers - note our Diagnostic Consent Form has been updated as of 5/1/2022.</p> */}
										</>
									)

								} else if (this.props.chosen_test.includes("Carrier Couple")) {
									return (
										<>
										<div>{coupCarrierGeneralDescription}</div>
											<div className={(this.state.isActive ? 'read-more is-active' : 'read-more')}>{CarrierCoupleDescription}</div>
											<br></br>
											{/* <p className='is-size-5 is-italic has-text-weight-bold'>* Submitting Healthcare Providers - note our Carrier Couple Consent Form has been updated as of 5/1/2022.</p> */}
										</>
									)
								} else if (this.props.chosen_test.includes("Carrier Individual")) {
									return (
										<>
											<div>{indCarrierGeneralDescription}</div>
											<div className={(this.state.isActive ? 'read-more is-active' : 'read-more')}>{CarrierIndividualDescription}</div>
											<br></br>
											{/* <p className='is-size-5 is-italic has-text-weight-bold'>* Submitting Healthcare Providers - note our Carrier Individual Consent Form has been updated as of 5/1/2022.</p> */}
										</>
									)   
								} else if (this.props.chosen_test.includes("Heredity")) {
									return (
										<>
											<div>{heredityIntro}</div>
											<div className={(this.state.isActive ? 'read-more is-active' : 'read-more')}>{heredityDescription}</div>
										</>
									)
								} else {
									return (
										<>
											<div>{constitutionalDiagnosticDescription}</div> 
											<div>{coupCarrierGeneralDescription}</div><div>{CarrierCoupleDescription}</div>
											<div>{indCarrierGeneralDescription}</div><div>{CarrierIndividualDescription}</div>
											<div>{heredityDescription}</div>
										</>
									)            
								}
							} else if (this.props.chosen_type === "SOMATIC") {
								if (this.props.chosen_test === "Constitutional"){
									return (
										<>
											<div>{somaticTestingIntro}</div>
											<div className={(this.state.isActive ? 'read-more is-active' : 'read-more')}>{somaticTestingDescription}</div> 
										</>
									)
								}
							}   
						})()}                        
						<button className="mt-4 is-link button" onClick={this.toggleReadMore}> 
							{this.state.isActive ? "Read Less" : "Read More"} 
							{this.state.isActive ? <Icon className="pl-1" path={mdiChevronUp} size={1}/> : <Icon className="pl-1" path={mdiChevronDown} size={1}/> }
						</button>
					</div>
					<div className="column"></div>
					<TestLinks chosen_type={this.props.chosen_type} chosen_test={this.props.chosen_test}/>
				</div>
				<div style={{marginTop:'20px'}} className="test-box-buttons-div">{this.props.TestLinksScrollToComponent()}</div>
				{this.props.getTests()}
			</div>            
			</>
		)
	}
}

export default TestCategory
