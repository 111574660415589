import React from "react"
import closeButton from "../../assets/icons/close.svg"
import rightAngle from "../../assets/icons/angle-right-solid.svg"
import { Link } from 'react-router-dom';


class TestHelp extends React.Component {

	render () {
		return (
			<>
			<div id="modal-background" onClick={this.props.passedFunction} className={this.props.modalState}></div>
			<div id="test-container" className={this.props.popUpState}>
				<img className="bio-close" src={closeButton} onClick={this.props.passedFunction} alt="Close Button"></img>
				<div className="is-shady fit-width-mod">
					<div className="bottom-border">
						<Link
							to={{
								pathname: "/test-list",
								state: {
									chosen_type: "SOMATIC",
									chosen_test: "Constitutional",
									pre_testing: "DISEASE"
								},
							}}
							className="post post-min-height has-text-black"
						>
							<div className="post-row is-size-4-desktop is-size-5-tablet is-size-6-mobile">
								<div className="post-row-spacing">
									<h1 className="has-text-weight-semibold has-text-link">Somatic Diagnostic</h1>
									<h1 className="">
										I have a malignancy (cancer, sarcoma, leukemia, lymphoma) that I want to find the correct therapy for.
									</h1>
								</div>
								<img src={rightAngle} className="right-angle-arrow" alt="Icon for Google"></img>
							</div>
						</Link>
					</div>
					<div className="bottom-border">
						<Link
							to={{
								pathname: "/test-list",
								state: {
									chosen_type: "CONSTITUTIONAL",
									chosen_test: "Constitutional",
									pre_testing: "DISEASE"
								},
							}}
							className="post post-min-height has-text-black"
						>
							<div className="post-row is-size-4-desktop is-size-5-tablet is-size-6-mobile">
								<div className="post-row-spacing">
									<h1 className="has-text-weight-semibold has-text-link">Constitutional Diagnostic</h1>
									<h1 className="">
										Me or my family member has a genetic disease that I want diagnosed.
									</h1>
								</div>
								<img src={rightAngle} className="right-angle-arrow" alt="Icon for Google"></img>
							</div>
						</Link>
					</div>
					<div className="bottom-border">
						<Link
							to={{
								pathname: "/test-list",
								state: {
									chosen_type: "CONSTITUTIONAL",
									chosen_test: "Carrier Individual",
									pre_testing: "DISEASE"
								},
							}}
							className="post post-min-height has-text-black"
						>
							<div className="post-row is-size-4-desktop is-size-5-tablet is-size-6-mobile">
								<div className="post-row-spacing">
									<h1 className="has-text-weight-semibold has-text-link">Carrier Individual</h1>
									<h1 className="">
										There is a disease running in my family, I want to know if I will be affected.
									</h1>
								</div>
								<img src={rightAngle} className="right-angle-arrow" alt="Icon for Google"></img>
							</div>
						</Link>
					</div>
					<div className="bottom-border">
						  <Link
								to={{
									pathname: "/test-list",
									state: {
										chosen_type: "CONSTITUTIONAL",
										chosen_test: "Carrier Couple",
										pre_testing: "DISEASE"
									},
								}}
								className="post post-min-height has-text-black"
							>
							<div className="post-row is-size-4-desktop is-size-5-tablet is-size-6-mobile">
								
								<div className="post-row-spacing">
									<h1 className="has-text-weight-semibold has-text-link">Carrier Couple</h1>
									<h1 className="">
										I want to test myself and my partner to make sure our children will be healthy.
									</h1>
								</div>
								<img src={rightAngle} className="right-angle-arrow" alt="Icon for Google"></img>
							</div>
						</Link>
					</div>
					<div className="bottom-border">
						<Link
							to={{
								pathname: "/test-list",
								state: {
									chosen_type: "CONSTITUTIONAL",
									chosen_test: "Heredity",
									pre_testing: ""
								},
							}}
							className="post post-min-height has-text-black"
						>
							<div className="post-row is-size-4-desktop is-size-5-tablet is-size-6-mobile">
								<div className="post-row-spacing">
									<h1 className="has-text-weight-semibold has-text-link">Heredity</h1>
									<h1 className="">
										I want to know my ethnic origins.
									</h1>
								</div>
								<img src={rightAngle} className="right-angle-arrow" alt="Icon for Google"></img>
							</div>							
						</Link>
					</div>
					<div className="bottom-border">
						<Link
							to={{
								pathname: "/test-list",
								state: {
									chosen_type: "CONSTITUTIONAL",
									chosen_test: "Constitutional",
									pre_testing: "DISEASE",
									scroll_to: "known-familial-variant"
								},
							}}
							className="post post-min-height has-text-black"
						>
							<div className="post-row is-size-4-desktop is-size-5-tablet is-size-6-mobile">
								<div className="post-row-spacing">
									<h1 className="has-text-weight-semibold has-text-link">Known Familial Variant</h1>
									<h1 className="">
										There was a variant detected in one of your family members and you would like to know if you have it as well, or you want to verify a variant that was reported to you from a nonclinical provider.
									</h1>
								</div>
								<img src={rightAngle} className="right-angle-arrow" alt="Icon for Google"></img>
							</div>
						</Link>
					</div>
				</div>
			</div>
			</>
		)
	}
}

export default TestHelp