import React from "react"
import closeButton from "../../assets/icons/close.svg"
import scholar from "../../assets/icons/google-scholar.svg"

class AboutBlurb extends React.Component {
    
    render () {
        return (
            <>
            <div id="modal-bio" className={this.props.popUpState}>
                <img className="bio-close" src={closeButton} onClick={this.props.passedFunction} alt="Close Button"></img>
                <div className="bio-image">
                    <img src={this.props.img} alt={this.props.name}></img>
                </div>
                <div className="bio-header">
                    <h3 className="title is-size-5-mobile">{this.props.name}</h3>
                    <h4 className="subtitle is-size-6-mobile">{this.props.title}</h4>
                </div>
                <div className="bio-body">
                    <p className="is-size-6 is-size-7-mobile">{this.props.blurb}</p>
                </div>
                <div className={"bio-social" + (this.props.link === "#" ? " hidden" : "")}>
                    <a href={this.props.link}>
                        <img src={scholar} className="social-img" alt="Link for Google"></img>
                    </a>
                </div>
            </div>
            </>
        )
    }
}

export default AboutBlurb