import styled from 'styled-components';
  
export const Heading = styled.h1`
    text-align: center;
    color: green;
`;
  
export const Content = styled.div`
    overflowY: scroll;
    height: 2500px;
`;
  
export const Button = styled.div``