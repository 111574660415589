import React from "react"

import AboutBlurb from "./AboutBlurb.js"
import TeamMember from "./TeamMember.js"

import nick from "../../assets/headshots/nick.png"
import peter from "../../assets/headshots/peter.png"
import fidi from "../../assets/headshots/fidi.png"
import paul from "../../assets/headshots/paul3.png"
import carl from "../../assets/headshots/carl-circle-co.png"

const team =  [
    {id: 0, name:'Peter Lajos Nagy, MD, PhD', title: 'Chief Medical Officer', img: peter, link:"https://scholar.google.com/citations?user=td0WLFkAAAAJ&hl=en", blurb: "Dr. Nagy graduated from Pécs Medical University, Hungary, in 1989. He received his PhD in biochemistry in 1995 from Purdue University. He completed his AP/Molecular genetic pathology residency and postdoctoral fellowship training at Stanford University Medical Center. Dr Nagy is a member of ASCP and CAP and serves as a CAP laboratory inspector. He is board certified in Anatomic and Molecular Genetic Pathology and has worked as molecular laboratory director since 2004 at the University of Iowa, Columbia University and Medical Neurogenetics Laboratories. Dr Nagy’s research interest is the development and application of next generation sequencing and other genomic technologies for molecular diagnostics. He has authored more than 30 articles published in such journals as Cell, Molecular Cell, and Proceedings of the National Academy of Sciences."},
    {id: 1, name:<p>Paul Smith, MB (ASCP)<sup>CM</sup></p>, title: 'Director of Laboratory Operations', img:paul, link:"", blurb: "Paul Smith received his BS in Biology from the University of North Georgia. He operated as a Field Team Leader for Georgia DNR Watershed Outreach before transitioning to the laboratory. He was department lead for the molecular lab at Medical Neurogenetics, where he is credited with developing the first commercially available LR-PCR test for sizing large repeats in FRDA patients. He is a certified member of the ASCP and serves as a CAP inspection team member. Paul specializes in rapid assay and systems development. His research interest is in repeat expansion diseases and long read sequencing technologies for molecular diagnostics."},
    {id: 2, name:'Nicholas Rouse, MS', title: 'Director of Bioinformatics', img: nick, link:"https://scholar.google.com/scholar?q=author:%22Rouse%20Nicholas%22", blurb: "Nicholas Rouse is the Head of Bioinformatics at Praxis Genomics, and his expertise is in next-generation sequencing (NGS) pipeline development, validation, and implementation. He completed his Bachelor of Science in mathematics from Drexel University and his Master of Science in biostatistics from Columbia University. He was previously the Director of Bioinformatics at Medical Neurogenetics Laboratories (MNG) where he focused on go-to-market strategy and launch of targeted, whole exome, whole transcriptome, and whole genome sequencing tests. Prior to MNG Nicholas worked at Columbia University in the Personalized Genomic Medicine Laboratory of the Department of Pathology and Cell Biology where he performed primary statistics and bioinformatics on the validations of clinical tests approved by the New York State Department of Health including cancer whole exome sequencing, targeted cancer panel, and whole exome sequencing.  He has authored more than 10 articles published in such journals as European Journal of Human Genetics – Nature."},
    {id: 3, name:'Michael F. Nagy, BA', title: 'Director of IT', img:fidi, link:"", blurb: "Michael F. Nagy is the Director of IT at Praxis Genomics. He completed his Bachelor of Arts in Computer Science at the University of Iowa in 2014. Upon graduation he worked for Neon Rain Interactive as a software developer and project manager. He was a founding member of Praxis Genomics in 2019. He is responsible for the establishment and oversight of the effective and smooth internal IT operations, setting policy to meet governance requirements with special emphasis on cybersecurity."},
    {id: 4, name:'Carl Woodham, PhD', title: 'Director of Business Development', img:carl, link:"", blurb: "Carl is a former prostate cancer researcher who received his PhD in Molecular Biology from the Illinois Institute of Technology and has research published out of the University of Illinois at Chicago.  After leaving the lab he worked in sales and business development for several leading biotech companies including Promega, Roche and Silicon Biosystems, providing support to pharma and biotech in the areas of genomics and data analysis for cancer and rare diseases"}
  ]

class TeamSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalState : ' has-modal-off',
            popUpState : ' bio-pop-up pop-up-off',
            bioName : '',
            bioTitle : '',
            bioBlurb : '',
            bioImg : '',
            bioLink : ''
        }
    }

    toggleState(){
        if (this.state.modalState === ' has-modal-off'){
            this.setState({popUpState: 'bio-pop-up pop-up-on'})
            this.setState({modalState: ' has-modal-on'})
        } else {
            this.setState({popUpState: ' bio-pop-up pop-up-off'})
            this.setState({modalState: ' has-modal-off'})
        }
    }

    setBio(index){
        this.setState({bioName: team[index].name})
        this.setState({bioTitle: team[index].title})
        this.setState({bioBlurb: team[index].blurb})
        this.setState({bioImg: team[index].img})
        this.setState({bioLink: team[index].link ? team[index].link : "#"})

        this.toggleState()
    }

    render () {
        return (
            <>
            <div id="modal-background" className={this.state.modalState}></div>
            <AboutBlurb 
                passedFunction = {this.toggleState.bind(this)}
                popUpState = {this.state.popUpState}
                name = {this.state.bioName}
                title = {this.state.bioTitle}
                blurb = {this.state.bioBlurb}
                img = {this.state.bioImg}
                link = {this.state.bioLink}
            />
            <div className="container add-padding-bottom2x" id="section3">
                <div className="add-padding-bottom2x">
                    <h2 className="no-margin-bottom title is-upper-alpha is-size-3-mobile is-size-1 is-size-1-tablet is-size-1-desktop has-text-centered">Leadership</h2>
                </div>
                <section className="">
                    <div className="container has-text-centered">
                        <div className="columns is-centered is-multiline">
                            {team.slice(0, 3).map((member, index) =>
                                <TeamMember
                                    key = {index}
                                    passedFunction = {() => this.setBio(member.id)}
                                    name = {member.name}
                                    title = {member.title}
                                    img = {member.img}                                     
                                />
                            )} 
                        </div>
                                                <div className="columns is-centered is-multiline">
                            {team.slice(3).map((member, index) =>
                                <TeamMember
                                    key = {index}
                                    passedFunction = {() => this.setBio(member.id)}
                                    name = {member.name}
                                    title = {member.title}
                                    img = {member.img}                                     
                                />
                            )} 
                        </div>
                    </div>
                </section>
            </div>
            </>
        )
    }
}

export default TeamSection