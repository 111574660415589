import React, { useState, useEffect } from 'react';
import './SchedulingPage.css';
import SubNavBar from '../components/SubNavBar';
import { consulting_text_array } from '../views/praxisData';

function Schedule() {
  const [calendar, setCalendar] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    setCalendar(
      <iframe
        title="Scheduler"
        src="https://calendly.com/praxisgenomics/consultation"
        className="fill-it"
        frameBorder="0"
      ></iframe>
    );

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <>
      <SubNavBar />
      <div className="container section">
        <div className="columns">
          <div className="column">
            <div className="section">
              <h1 className="is-size-3-desktop is-size-3 has-text-weight-bold">
                {consulting_text_array["title"]}
              </h1>
              <br></br>
              {Object.entries(consulting_text_array).map(([key, value], idx) => {
                if (idx === 0) return null;
                return (
                  <React.Fragment key={key}>
                    <p className="is-size-5-desktop is-size-5-mobile">{value}</p>
                    <br></br>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className="column min-height-iframe">{calendar}</div>
        </div>
      </div>
    </>
  );
}

export default Schedule;