import React from "react";
import { useEffect, useState, useRef } from "react";

import bioNano from "../../assets/images/side-by-side.png"
import novaseqx from "../../assets/images/novaseq-x.png"
import Stripes from '../components/Stripes'
import dragen from "../../assets/images/dragen.png"
import genoox from "../../assets/images/genoox.png"
import mgiT7 from "../../assets/images/mgi-T7.png"
import minION from "../../assets/images/minION2.png"
import promION from "../../assets/images/promethionsolo.png"

import '../CSS/TechnologySection.css'

function TechnologySection(props) {
    const sectionRef = useRef(null)
    const [stripesHeight, setStripesHeight] = useState('100%');

    useEffect(() => {
        const calculateStripesHeight = () => {
          const sectionHeight = sectionRef.current.offsetHeight + 150;
          setStripesHeight(`${sectionHeight}px`);
        };
      
        calculateStripesHeight();
      
        const resizeObserver = new ResizeObserver(calculateStripesHeight);
        resizeObserver.observe(sectionRef.current);
      
        return () => {
          resizeObserver.disconnect();
        };
      }, []);

    return (
        <>
        <Stripes
            id="stripes"
            height={stripesHeight}
        />
        <div ref={sectionRef} className="container upper-layer is-relative has-text-white bumper-top-tech bumper-bottom" id="section1">
            <div className="section add-padding-bottom">
                <h2 className="title is-upper-alpha is-size-3-mobile is-size-1 is-size-1-tablet is-size-1-desktop has-text-centered has-text-white">Technology</h2>
            </div>
            <div className="columns reverse-column-order">
                <div className="column section center-vertically">
                    <p className="title has-text-white">Saphyr Whole Genome Optical Mapping Instrument</p>
                    <div className="content is-size-5-desktop is-size-6-tablet">
                        <p>The Saphyr® Whole Genome Optical Mapping Instrument from Bionano Genomics provides a high level assessment of breaks, rearrangements and repeat expansions in the DNA that cannot be seen using sequencing</p>
                        <a className="tag is-primary" href="https://bionanogenomics.com/products/saphyr/" target="_blank" rel="noopener noreferrer"> Learn More </a>
                    </div>
                </div>
                <div className="column is-half-desktop center-vertically">
                    <img className="bionano-side-by-side" alt="Bionano Sequencing Machine" src={bioNano}></img>
                </div>
            </div>
            <div className="columns">
                <div className="column is-half-desktop">
                    <img className="bionano-side-by-side" alt="Illumina Novaseq 6000 System" src={mgiT7}></img>
                </div>
                <div className="column section center-vertically">
                    <p className="title has-text-white">DNBSEQ-T7</p>
                    <div className="content is-size-5-desktop is-size-6-tablet">
                        <p>DNBSEQ-T7 can generate 1-7Tb of high-quality data per day for a wide range of applications, including whole genome, deep exome, epigenome, transcriptome, and targeted panel sequencing projects.</p>
                        <a className="tag is-primary" href="https://completegenomics.mgiamericas.com/t7" target="_blank" rel="noopener noreferrer"> Learn More </a>
                    </div>
                </div>
            </div>
            <div className="columns reverse-column-order">
                <div className="column section center-vertically">
                    <p className="title has-text-white">NovaSeq X System</p>
                    <div className="content is-size-5-desktop is-size-6-tablet">
                        <p>The NovaSeq X Sequencing System allows diagnosis of DNA mutations at the level of its building blocks</p>
                        <a className="tag is-primary" href="https://www.illumina.com/systems/sequencing-platforms/novaseq-x-plus.html" target="_blank" rel="noopener noreferrer"> Learn More </a>
                    </div>
                </div>
                <div className="column is-half-desktop">
                    <img className="bionano-side-by-side" alt="Illumina Novaseq X System" src={novaseqx}></img>
                </div>
            </div>
            <div className="columns">
                <div className="column is-half-desktop">
                    <img className="bionano-side-by-side" alt="Promethion Sequencing Machine" src={promION}></img>
                </div>
                <div className="column section center-vertically">
                    <p className="title has-text-white">Oxford Nanopore Technologies PromethION</p>
                    <div className="content is-size-5-desktop is-size-6-tablet">
                        <p>The PromethION sequencer allows diagnosis of DNA mutations that scale between the building block resolution and the high level assessment.</p>
                        <a className="tag is-primary" href="https://nanoporetech.com/products/promethion" target="_blank" rel="noopener noreferrer"> Learn More </a>
                    </div>
                </div>
            </div>
            <div className="columns reverse-column-order">
                <div className="column section center-vertically">
                    <p className="title has-text-white">Oxford Nanopore Technologies minION</p>
                    <div className="content is-size-5-desktop is-size-6-tablet">
                        <p>The minION combines long read sequencing technology with rapid data availability for fast variant confirmation.</p>
                        <a className="tag is-primary" href="https://nanoporetech.com/products/minion" target="_blank" rel="noopener noreferrer"> Learn More </a>
                    </div>
                </div>
                <div className="column is-half-desktop">
                    <img className="bionano-side-by-side" alt="Minion Sequencing Machine" src={minION}></img>
                </div>
            </div>
            <div className="columns">
                <div className="column is-half-desktop">
                    <img className="bionano-side-by-side" alt="Dragen sequencing logo" src={dragen}></img>
                </div>
                <div className="column section center-vertically">
                    <p className="title has-text-white">Illumina DRAGEN Bio-IT Platform</p>
                    <div className="content is-size-5-desktop is-size-6-tablet">
                        <p>The Dragen analysis pipeline allows high sensitivity detection of mutations and instability in difficult to analyze repetitive regions</p>
                        <a className="tag is-primary" href="https://www.illumina.com/products/by-type/informatics-products/dragen-bio-it-platform.html" target="_blank" rel="noopener noreferrer"> Learn More </a>
                    </div>
                </div>
            </div>
            <div className="columns reverse-column-order">
                <div className="column section center-vertically">
                    <p className="title has-text-white">Genoox</p>
                    <div className="content is-size-5-desktop is-size-6-tablet">
                        <p>The Genoox analysis pipeline allows co-interpretation, confirmation and up to date annotation of findings obtained using different technologies</p>
                        <a className="tag is-primary" href="https://www.genoox.com/solutions/genoox-for-clinical-genetics/" target="_blank" rel="noopener noreferrer"> Learn More </a>
                    </div>
                </div>
                <div className="column is-half-desktop">
                    <img className="bionano-side-by-side" alt="Bionano Optical Mapping machine." src={genoox}></img>
                </div>
            </div>
        </div>
        </>
    )
}

export default TechnologySection;